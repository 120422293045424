<template>
    <div>
        <Modal
            class-name="set-pump-by-wallet"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            title="设置钱包下所有设备抽水比例"
            :footer-hide="true"
        >
            <div class="box" v-loading="loading">
                <p style="margin-bottom: 20px;">
                    单独针对钱包 <span style="color: #ffab00;">{{ targetWallet }}</span> 下所有设备的抽水比例调整, 不影响其他设备, 如果同时设置了 单独矿机比例以及钱包比例, 优先级按照 单独矿机设置比例 > 钱包比例 > 端口比例。
                </p>
                <Form :label-width="120" v-bind:key="index" v-for="(item, index) in portWallets">
                    <FormItem :label="item.addr">
                        <Slider v-model="item.ratio" :step="0.01" :min="0" :max="100" show-input></Slider>
                    </FormItem>
                </Form>
                <div>
                    <ButtonGroup style="width: 100%;">
                        <Button @click.native="save()" style="margin-bottom: 10px;width: 50%;" type="success" long>保存配置</Button>
                        <Button @click.native="reset()" style="margin-bottom: 10px;width: 50%;" type="primary" long>还原配置</Button>
                    </ButtonGroup>
                    <Button @click.native="hide()" type="default" long>取消</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'


export default {
    data() {
        return {
            loading: false,
            visable: false,
            targetWallet: '',
            portId: '',
            portWallets: [],
            callback: null
        }
    },
    components: {
    },
    computed:{
    },
    mounted() {
       
    },
    methods:{
        ...mapActions('port', [
            'updatePorts'
        ]),
        show(row, portId, portWallets, callback) {
            this.portWallets = []

            portWallets.forEach(item => {
                this.portWallets.push({
                    addr: item.addr,
                    id: item.id,
                    ratio: item.ratio * 100
                })
            })

            this.targetWallet = row.id

            this.portId = portId;

            this.visable = true

            callback && (this.callback = callback)
        },
        hide() {
            this.visable = false
        },
        saveOnlyWallet(data) {
            let targetWallet = this.targetWallet;

            return new Promise((resolve, reject) => {
                this.axios({
                    method: 'post',
                    url: this.$API.setWalletRatio.replace('{id}', this.portId),
                    data: {
                        wallet: targetWallet,
                        wid: data.id,
                        r: data.ratio / 100
                    }
                }).then(res => {
                    if (res.status == 200) {
                        this.$Message.success(`更新成功，钱包 ${targetWallet} 下的所有矿机抽至 ${data.addr} 钱包的比例将发生变化 !`)
                        resolve()
                    } else {
                        this.$Message.error('设置失败!')
                        resolve()
                    }
                })
            })
        },
        async save() {
            for (let i = 0; i < this.portWallets.length; i++) {
                await this.saveOnlyWallet(this.portWallets[i])
            }

            this.hide()

            this.callback && this.callback()
        },
        reset() {
            this.axios({
                method: 'delete',
                url: this.$API.resetWalletRatio.replace('{id}', this.portId).replace('{wallet_name}', this.targetWallet)
            }).then(res => {
                if (res.status == 200) {
                    this.$Message.success('已恢复。')

                    this.hide()

                    this.callback && this.callback()
                } else {
                    this.$Message.error('还原失败。')
                }
            })
        }
    }
}
</script>

<style lang="scss">
.set-pump-by-wallet{
    .ivu-form-item-label{
        white-space: nowrap; /* 不换行 */
        text-overflow: ellipsis; /* 使用省略号表示溢出内容 */
        overflow: hidden;
    }
}
</style>

<style lang="scss">
@media screen and (max-width: $hideMenuWidth){
    .set-pump-by-wallet{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.set-pump-by-wallet{
    margin: 0 auto;
    width: 50%;
    max-width: 600px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .ivu-modal-body{
        padding: 0;
    }

    .box{
        padding: 20px;
    }
}
</style>
