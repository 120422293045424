import Vue from 'vue'
import Vuex from 'vuex'
import port from './modules/port.js'
import meta from './modules/meta.js'
import storage from './modules/storage.js'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		port,
		meta,
		storage
	}
})