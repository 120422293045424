<template>
    <div class="select-preset-wallet">
        <Modal
            class-name="select-preset-wallet-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            title="选择快捷钱包地址"
            :footer-hide="true"
        >
            <div v-loading="loading">
                <p style="margin-bottom: 20px;">- 此处便捷的选择钱包地址, 您也可以在 首页菜单栏 - 设置 - 添加快捷钱包地址 处来添加自定义地址。</p>
                <p style="margin-bottom: 20px;">- 钱包预览</p>
                <el-tabs type="card" class="device-list-tab">
                    <el-tab-pane class="device-list-tab-panel" label="自定义钱包">
                        <el-table
                            :data="presetWallet"
                            style="width: 100%"
                            :empty-text="`暂无自定义钱包。`"
                        >
                            <el-table-column
                                prop="name"
                                label="备注"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                label="钱包地址"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-link type="success" @click="selectItem(scope)">
                                        选择
                                    </el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </Modal>
    </div>
</template>
<script>

import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            // selectName: '',
            // selectAddress: '',
            endPoint: {},
            presetWallet: [
            ],
            callback: null
        }
    },
    components: {
        // TermCon
    },
    computed: {
        ...mapGetters('meta', {
            getCurrency: 'getCurrency',
            getSortCurrency: 'getSortCurrency'
        })
    },
    mounted() {
        
    },
    methods: {
        show(callback) {
            this.visable = true

            callback && (this.callback = callback)

            this.getEndPoint()
        },
        hide() {
            this.visable = false
        },
        selectItem(scope) {
            this.callback && this.callback(scope.row)

            this.hide()
        },
        getEndPoint() {
            let that = this;
            this.loading = true;

            this.axios({
                method: 'get',
                url: that.$API.getEndPoint
            }).then(res => {
                if (res.status == 200) {
                    that.endPoint = res.data

                    this.renderList();
                } else {
                    that.$Message.error('获取数据失败');
                }

                this.loading = false
            })
        },
        renderList() {
            let customWallet = this.endPoint.customWallet;

            if (!customWallet) {
                return
            }   

            let res = []

            if (customWallet.length) {
                res = customWallet
            }

            this.presetWallet = res
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .select-preset-wallet-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .select-preset-wallet-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.select-preset-wallet-model{
    margin: 0 auto;
    width: 50%;
    max-width: 700px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .el-tabs__item{
        font-size: 12px;
    }

    .pre-name{
        display: flex;
        align-items: center;

        .name-coin{
            height: 20px;
            display: block;
            margin-right: 5px;
        }
    }
}

.select-preset-wallet-select-coin-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
        display: block;
        // width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    span{
        font-size: 14px;
    }
    .engine{
        font-size: 12px;
        flex: 1;
        text-align: right;
    }
}
</style>
