export const lang = {
    theme: 'Theme',
    logout: 'Logout',
    menu: {
        dashboards: 'Dashboard',
        poolproxy: 'Pool Proxy',
        totalproxy: 'View All Proxies',
        addproxy: 'Add New Proxy',
        importproxy: 'Import Proxy Configuration',
        exportproxy: 'Export Proxy Configuration',
        notproxy: 'Proxy for this currency not added',
        setting: 'Settings',
        setcert: 'Set Global TLS/SSL Certificate',
        setaccount: 'Change Username and Password',
        ipban: 'IP Blacklist',
        offline: 'Offline Alert',
        webport: 'Modify WEB Access Port',
        addwallet: 'Add Shortcut Wallet',
        addpool: 'Add Shortcut Mining Pool',
        uuid: 'View Local UUID',
        restart: 'Restart Software',
        log: 'Log Management',
        algo: 'Algorithm Engine',
        front: 'Front Proxy',
        group: 'Group Control',
        about: 'About Us',
        version: 'Software Version',
        error_log: 'View Error Log'
    },
    sun: 'Dark Theme',
    moon: 'Light Theme',
    proxy: {
        newproxy: 'Add new proxy',
        editproxy: 'Edit port',
        t_deal: 'Proxy protocol',
        t_default: 'Basic configuration',
        t_fee: 'Fee configuration',
        t_conn: 'Connection mode',
        t_replace: 'Replace specified wallet',
        create_proxy: 'Create proxy',
        tcp_intro: 'Plain text connection, faster transmission speed, and vulnerable to man-in-the-middle attacks.',
        ssl_intro: 'Certificate encryption, slightly affecting transmission efficiency (the built-in certificate is used by default. If you need to use a custom certificate, please go to Settings-Certificate Settings to upload the certificate).',
        rms_intro: 'Own protocol, can compress the number of devices and data transmission to this software, fast transmission speed, and cannot be attacked or forged request attacks by middlemen, but requires a front-end proxy client.',
        port_num: 'Port number',
        proxy_coin: 'Proxy coin',
        max_conn: 'Maximum connections',
        remark: 'Port remark',
        proxy_addr: 'Proxy mining pool address',
        proxy_addr_des: 'Enter or select the target mining pool address, such as btc.f2pool.com:1314',
        proxy_addr_deal: 'Mining pool protocol',
        conn_test: 'Connectivity test',
        conn_test_ok: 'Connection successful',
        conn_test_no: 'Connection failed',
        stand_pool: 'Standby mining pool address',
        stand_pool_des: 'Standby mining pool address. After setting, if the target mining pool encounters problems, it will automatically switch to the standby mining pool to work.',
        stand_pool_deal: 'Standby mining pool protocol',
        des_remark: 'Remark',
        new_wallet: 'Add new wallet',
        allot_wallet: 'Allocate wallet',
        fee_addr: 'Fee mining pool address',
        fee_addr_message: 'If the ratio is set, this item is required.',
        fee_addr_des: 'Extract fees in this mining pool address. It is recommended to be the same as the target mining pool address.',
        fee_deal: 'Fee mining pool protocol',
        wallet_addr: 'Wallet address or sub-account',
        wallet_perc: 'If the ratio is set, this item is required.',
        wallet_desc: 'If it is a dual-mining currency, the configuration format here should be wallet address.wallet address',
        fee_name: 'Fee name',
        fee_name_desc: 'Device name for extracting computing power.',
        fee_percent: 'Fee ratio (%)',
        delete_wallet: 'Delete Allocated Wallet',
        err_port: 'Please enter the port to be monitored, ranging from 1 to 65534',
        err_proxy_coin: 'Please select the currency to be proxy',
        err_pool_addr: 'Please enter or select the mining pool',
        err_pool_mode: 'This item is required in mining pool mode',
        conn_eff: 'Efficient mode',
        conn_eff_des: 'A complete connection mode and mechanism, almost no loss of extraction and non-disconnected connections, and automatically switch incompatible devices to compatible mode.',
        conn_com: 'Compatible mode',
        conn_com_des: 'Obsolete kernel or model and some mining pools with poor compatibility will cause excessive invalid computing power. At this time, this mode should be selected, which will cause a certain amount of loss.',
        conn_pool: 'Mining pool mode',
        conn_pool_des: 'Merge all mining pool connections, greatly reduce the bandwidth of mining pool direction, all miners will be merged under this mode, and wallet and device names will be unified, suitable for your own mining farm (use with caution).',
        unify_wallet: 'Unify Wallet',
        unify_wallet_des: 'Replace the wallet address on all devices.',
        unify_name: 'Unify Device Name',
        unify_name_des: 'Replace the name on all devices.',
        replace_err: 'Both options must be set or unset at the same time',
        ori_wallet: 'Original Wallet',
        ori_wallet_des: 'Replace the matching wallet address with the specified wallet address. Supports multiple wallet addresses in one line format.',
        target_wallet: 'Replace with',
        target_wallet_des: 'Replace the wallet address with the address here.',
        create_error: 'Creation failed',
        create_config_error: 'Configuration check failed. Please make the necessary changes as prompted.',
        alert_warning: 'Warning⚠️',
        alert_warning_text: 'The fee pool is set to the same address as the target pool by default. If you specify a separate fee pool, it may cause issues. If you really want to specify a separate fee pool address, please enter "I understand that the different addresses of agent pool and commission pool may cause the equipment to fail to work normally" in the input box below.',
        alert_warning_text2: 'At least one wallet configuration must be kept, even if no proportion is configured.',
        alert_warning_text3: 'This protocol is not open',
        alert_warning_text4: 'RMS client is currently under development, please wait a few days.'
    },
    router: {
        login: 'Login',
        dashboard: 'Dashboard',
        com: 'Statistics',
        pool_proxy: 'Pool Proxy'
      },
    cancel: 'Cancel',
    pleaseSelect: 'Please select',
    alert: {
        set_success: 'Set successfully',
        set_lang: 'Unswitched languages will take effect after the page is manually refreshed.'
      },
    hashrate: 'Hashrate',
    chart: {
        effect: 'Effective hashrate',
        invalid: 'Invalid hashrate',
        pump: 'Extraction hashrate',
        e_s: 'Effective shares',
        i_s: 'Invalid shares',
        p_s: 'Extraction shares'
    },
    eff_hash: 'Effective hashrate',
    eff_hash_r: 'Current effective hashrate',
    fuck_error: 'Fatal error, please contact the administrator.',
    nons_title: 'Not Open',
    nons_text: 'This feature is still under development, please wait a few more days.',
    attack: 'Attack interception',
    ping: 'Average latency',
    c_ping: 'Current average latency',
    count: 'times',
    desk_per: 'Miner Usage',
    desk_title: 'Disk Usage',
    desk_total: 'Total usage',
    desk_pro: 'Program usage',
    desk_res: 'Remaining available',
    login: {
        hello: 'Welcome to use 👋',
        message: 'Login to your account and start experiencing the best farm operation and maintenance tools.',
        user: 'Username',
        user_des: 'Please enter your username',
        pass: 'Password',
        pass_des: 'Please enter your password',
        verify: 'Verification code',
        verify_des: 'Please enter the picture content',
        reme: 'Remember account',
        forgot: 'Forgot Password?',
        login: 'Login'
    },
    logout_titme: 'Log out?',
    logout_text: 'The current login user will be logged out soon.',
    server_run_time: 'Program has been running:',
    notice: 'Notice:',
    add_proxy: 'Add new proxy',
    hash_ratio: 'Current hashrate',
    coin_active_rank: 'Device activity distribution',
    rank: 'Ranking',
    coin: 'Coin',
    hash: 'Hashrate',
    active_device: 'Active devices',
    active_device_r: 'Current active',
    die_device: 'Offline devices',
    current_die: 'Current offline',
    ratio: 'Ratio',
    get_config_error: 'Failed to get configuration information!',
    save_success: 'Saved successfully～',
    save_error: 'Save failed!',
    save_error_1: 'Failed to save configuration because the port was not closed.',
    create_success: 'Created successfully～',
    create_error: 'Creation failed!',
    update_config: 'Update configuration',
    pool_hash: 'Pool hashrate',
    system_log: 'Operation log',
    error_hash: 'Invalid hashrate',
    error_hash_ratio: 'Current invalid hashrate/ratio',
    net_stats: 'Traffic statistics',
    net_stats_des: 'Upstream/downstream resources consumed by the program',
    net_up: 'Upstream',
    net_down: 'Downstream',
    active_device_stats: 'Active device statistics',
    total_pool: 'All pools',
    active_device_ratio: 'Device activity rate',
    port_deal: 'Port protocol',
    port_des: 'Proxy port/remarks',
    port_line: 'Online/offline',
    current_hash: 'Current hashrate',
    port_ratio: 'Efficiency',
    connect_addr: 'Connection address',
    status: 'Status',
    opa: 'Operation',
    show_detail: 'View details',
    switch_port: 'Open/close port',
    edit_port: 'Edit port',
    hot_update_wallets: 'Hot update fee configuration',
    delete_port: 'Delete port',
    port_status_success: 'Port status normal',
    port_status_close: 'Port has been closed',
    port_status_queue: 'Queuing, please wait...',
    delete_port_aff: 'Are you sure you want to delete this port?',
    delete_port_ok: 'Successfully deleted port',
    delete_port_error: 'Failed to delete port',
    close_port_ok: 'Port closed successfully',
    close_port_error: 'Failed to close port',
    open_port_ok: 'Port opened successfully',
    open_port_error: 'Failed to open port',
    copy_conn: 'Connection address copied',
    real_stats: 'Real-time statistics',
    time_15_hash: '15-minute average hash rate:',
    time_4_hash: '4-hour average hash rate:',
    time_24_hash: '24-hour average hash rate:',
    e: 'Effective',
    f: 'Invalid',
    c: 'Extract',
    real_ping: 'Average latency',
    ready_port: 'Port ready',
    no_port: 'No proxy available',
    port: 'Port',
    click_create: 'Click to create'
}