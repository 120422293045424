const CONFIG = {
    TITLE: 'RustMinerSystem',
    TELEGRAM: 'https://t.me/rustkt',
    GITHUB: 'https://github.com/EvilGenius-dot/RustMinerSystem',
    AUTHOR: 'EvilGenius',
    SORT_CURRENCY: [
        'NGINX', 
        'BTC', 
        'BCH',
        'LTC',
        'KASPA',
        'KAS(ks0,1,2)',
        'KAS(ks3)',
        'KLS',
        'ETC',
        'ETHW',
        'ETHF',
        'ETC+ZIL',
        'ETHW+ZIL',
        'ETHF+ZIL',
        'OCTA',
        'CFX',
        'KDA',
        'SC',
        'ZEC',
        'ZEN',
        'HNS',
        'LBC',
        'DNX',
        'DASH',
        'BLOCX',
        'CKB',
        'SERO',
        'ALPH',
        'XMR',
        'ZEPH',
        'RVN',
        'RXD',
        'ERG',
        'CLORE',
        'NEURAI',
        'NEOXA',
        'ZIL',
        'CLO',
        'UBQ',
        'EGAZ',
        'ELH',
        'AVS',
        'CAU',
        'PAC',
        'PWR',
        'BTN',
        'DUBX',
        'XPB',
        'REDEV2',
        'RTH',
        'NEXA',
        'RTM',
        'RTC',
        'MECU',
        'MAXE',
        'NIKI',
        'SUBI',
        'MEWC',
        'BEL',
        'PYI',
        'IRON',
        'DOGETHER',
        'AIPG'
    ],
    // 支持计算算力的分类
    SUPPORT_CATEGORY: [
        'ethhash',
        'eth1hash',
        'ethhash2d',
        'sha256',
        'kheavyhash',
        'scrypt',
        'dynexsolve',
        'octopus',
        'blake2s',
        'eaglesong',
        'equihash',
        'blake2b',
        'lbry',
        'x11',
        'progpow',
        'blake3',
        'randomx',
        'kawpow',
        'sha512256d',
        'autoykos2',
        'nexapow',
        'karlsenhash',
        'ghostrider',
        'kawpow2',
        'blake3s'
    ],
    API: {
        // mock
        // getTotalDevice: '/api/getTotalDevice',
        // getMeta: '/api/meta',
        // getWorkerChart: '/api/getWorkerChart',
        // getPortChart: '/api/getPortChart',
        // getCoinChart: '/api/getCoinChart',
        // proxy
        getPorts: '/api/ports',
        getPortById: '/api/port/{id}',
        getWorkers: '/api/port/{id}/workers',
        createPort: '/api/port/new',
        editPort: '/api/port/{id}',
        updateWallet: '/api/wallet/{id}',
        deleteWallet: '/api/wallet/{id}',
        addWallet: '/api/wallet/new',
        closePort: '/api/port/{id}/stop',
        startPort: '/api/port/{id}/start',
        deletePort: '/api/port/{id}',
        getPortLog: '/api/port/{id}/log',
        uploadCert: '/api/cert/upload',
        getCert: '/api/cert',
        resetCert: '/api/cert/reset',
        getCurrency: '/api/currency/config',
        captcha: '/api/_/captcha',
        login: '/api/_/login',
        logout: '/api/_/logout',
        updateEngine: '/api/config/refresh',
        getWorkerChart: '/api/stat/worker/{id}',
        getPortChart: '/api/stat/port/{id}',
        getCoinChart: '/api/stat/currency/{currency}',
        setPassword: '/api/_/changepassword',
        getTotalDevice: '/api/device/stats',
        getLog: '/api/request/log',
        setWebPort: '/api/web/port/restart',
        getSystemInfo: '/api/sysinfo',
        getOriginConfig: '/api/version',
        getStartTime: '/api/sys/starttime',
        getWorkerFail: '/api/worker/{id}/error/log',
        getLocalVersion: '/api/local/version',
        ping: '/api/ping',
        importPort: '/api/ports/import',
        errlog: '/api/error/log',
        runlog: '/api/running/log',
        getUUID: '/api/u/i',
        getWorkerLog: '/api/group/{gid}/log',
        getTotalWallet: '/api/port/{id}/g/wallet/list',
        getTotalWallets: '/api/port/{id}/g/wallets/{collect}',
        ctWallet: '/api/port/{id}/wallet/collect',
        getGWorker: '/api/port/{id}/g/workers',
        setWorkerRatio: '/api/port/{id}/worker/ratio',
        getWorkerRatio: '/api/wallet/{wallet_id}/worker/{sid}/ratio',
        getRMSConfig: '/api/rms/config',
        setRMSConfig: '/api/rms/config',
        getRelease: '/api/releases',
        getMob: '/api/mb/port',
        setHttps: '/api/web/tls/{flag}',
        getHttps: '/api/web/tls',
        serverJAPI: '/api/warning/setting',
        getServerJAPI: '/api/warning/setting',
        getIP: '/api/ip/coming',
        freeIP: '/api/ip/blacklist/delete',
        banIP: '/api/ip/blacklist/add',
        getBanIP: '/api/ip/blacklist',
        get_pump_t: '/api/pump/t',
        pump_t: '/api/pump/t/{pump_t}',
        get_currency_online: '/api/currency/online/stat/{coin}',
        base_info: '/api/sys/base/info',
        getBaseChart: '/api/sys/stat',
        setEndPoint: '/api/local/pool/endpoint',
        getEndPoint: '/api/local/pool/endpoint',
        getPoolInfo: '/api/pool/info/{id}',
        getControlAPI: '/api/remote/token',
        getControlList: '/api/remote/list',
        createControl: '/api/remote/new',
        deleteControl: '/api/remote/{id}',
        updateControl: '/api/remote/{id}',
        remoteControl: '/api/remote/q/{id}/{m}/{p}',
        refreshControl: '/api/remote/refresh/token',
        banWallet: '/api/wallet/blacklist/add',
        getBanWallet: '/api/wallet/blacklist',
        deleteBanWallet: '/api/wallet/blacklist/delete',
        getWalletRatio: '/api/port/{id}/wallet/ratio',
        setWalletRatio: '/api/port/{id}/wallet/ratio',
        resetWalletRatio: '/api/port/{id}/wallet/{wallet_name}/ratio'
    }
}

export default{
    CONFIG
}