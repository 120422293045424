<template>
    <Form @submit.native.prevent class="default-config-class" ref="validateRef" :model="ProxyData" label-position="top" :rules="RuleValidate">
        <Row :gutter="25" type="flex" justify="start">
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.port_num')" prop="Port">
                    <InputNumber v-model="ProxyData.Port" empty placeholder="1-65534" :max="65534" :min="1" long style="width: 100%;"></InputNumber>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.proxy_coin')" prop="ProxyCoin" class="port-coin-select">
                    <Select filterable transfer @on-change="activeCoinChange" v-model="ProxyData.ProxyCoin" style="width:100%;" :placeholder="$t('lang.pleaseSelect')">
                        <Option v-for="(val, key, index) in getSortCurrency" :key="index" :value="val.name" :label="val.name">
                            <div class="select-coin-item fixed-select-coin-item">
                                <img :src="$Func.getCoinImage(`${(val.name).toLowerCase()}`)" />
                                <span>{{ transName(val.name) }}</span>
                            </div>
                        </Option>
                    </Select>
                    <div v-if="ProxyData.ProxyCoin == 'BTC' || ProxyData.ProxyCoin == 'BCH' || ProxyData.ProxyCoin == 'LTC'">
                        <el-tooltip class="item" effect="dark" content="无法连接OKMiner的矿机, 可通过开启此选项进行优化。" placement="bottom">
                            <p class="s-tit">
                                <i class="question el-icon-question"></i>
                                OKMiner优化:
                            </p>
                        </el-tooltip>
                        <Radio-group>
                            <el-radio v-model="ProxyData.setting.op" :label="1">开启</el-radio>
                            <el-radio v-model="ProxyData.setting.op" :label="0">关闭</el-radio>
                        </Radio-group>
                    </div>
                    <div v-if="getCurrency[ProxyData.ProxyCoin] && (getCurrency[ProxyData.ProxyCoin].category == 'dynexsolve')">
                        <p class="mallob-title s-tit">mallob地址:</p>
                        <p class="mallob-p" @click="mobTips"> 
                            <i class="el-icon-success success" v-if="getMob.mobStatus == 1"></i>
                            <i class="el-icon-error error" v-if="getMob.mobStatus != 1"></i>
                            <span>{{getMob.mobURL}}</span>
                        </p>
                    </div>
                    <div v-if="getCurrency[ProxyData.ProxyCoin] && (getCurrency[ProxyData.ProxyCoin].category == 'kheavyhash' || getCurrency[ProxyData.ProxyCoin].category == 'ethhash' || getCurrency[ProxyData.ProxyCoin].category == 'ethhash2d')">
                        <el-tooltip class="item" effect="dark" content="最大限度保护客户算力损耗, 开启后可能会抽不够, 只在客户发生损耗之前抽到尽量多的算力, 但是客户算力不会损耗。如果关闭, 以抽水精准为目标, 但不对客户算力做任何保护措施, 自行斟酌开关。" placement="bottom">
                            <p class="s-tit">
                                <i class="question el-icon-question"></i>
                                算力保护:
                            </p>
                        </el-tooltip>
                        <Radio-group>
                            <el-radio v-model="ProxyData.setting.pth" :label="0">开启</el-radio>
                            <el-radio v-model="ProxyData.setting.pth" :label="1">关闭</el-radio>
                        </Radio-group>
                    </div>
                    <!-- <div v-if="getCurrency[ProxyData.ProxyCoin] && (getCurrency[ProxyData.ProxyCoin].category == 'ethhash' || getCurrency[ProxyData.ProxyCoin].category == 'ethhash2d')">
                        <el-tooltip class="item" effect="dark" content="优化矿机的并发连接, 一些机型需要关闭, 如gtv66。" placement="bottom">
                            <p class="s-tit">
                                <i class="question el-icon-question"></i>
                                并发优化:
                            </p>
                        </el-tooltip>
                        <Radio-group>
                            <el-radio v-model="ProxyData.setting.cut" :label="0">开启</el-radio>
                            <el-radio v-model="ProxyData.setting.cut" :label="1">关闭</el-radio>
                        </Radio-group>
                    </div> -->
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.max_conn')">
                    <InputNumber v-model="ProxyData.MaxConnect" :value="0" placeholder="最大65535" :max="65535" :min="0" long style="width: 100%;"></InputNumber>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.remark')">
                    <Input v-model="ProxyData.Info" :placeholder="$t('lang.proxy.des_remark')"></Input>
                </FormItem>
            </Col>
            <Col :xs="24" :md="12" :lg="12">
                <FormItem class="input-btn-group" :label="$t('lang.proxy.proxy_addr')" prop="ProxyPool.Pool">
                    <Input v-model="ProxyData.ProxyPool.Pool" @on-change="poolURLChange" :placeholder="$t('lang.proxy.proxy_addr_des')"></Input>
                    <Button type="primary" @click="selectPreProxy('proxy')">
                        <i class="el-icon-place"></i>
                        快速选择
                    </Button>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.proxy_addr_deal')">
                    <el-radio @change="dealChange" v-model="ProxyData.ProxyPool.Deal" label="TCP">TCP</el-radio>
                    <el-radio @change="dealChange" v-model="ProxyData.ProxyPool.Deal" label="SSL">SSL</el-radio>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.conn_test')">
                    <!-- 请输入或选择一个矿池地址 -->
                    <beat-ani v-if="pStatus == 'ping'"></beat-ani>
                    <span class="info" v-show="pStatus == 'wait'">
                        等待输入
                    </span>
                    <span v-show="pStatus == 'ok'" class="success">
                        <Icon type="md-checkmark-circle-outline" />{{$t('lang.proxy.conn_test_ok')}}
                    </span>
                    <span v-show="pStatus == 'error'" class="error">
                        <Icon type="md-close" />{{$t('lang.proxy.conn_test_no')}}
                    </span>
                </FormItem>
            </Col>
            <Col :xs="24" :md="12" :lg="12">
                <FormItem class="input-btn-group" :label="$t('lang.proxy.stand_pool')">
                    <Input v-model="ProxyData.SparePool.Pool" @on-change="sPoolURLChange" :placeholder="$t('lang.proxy.stand_pool_des')"></Input>
                    <Button type="primary" @click="selectPreProxy('spare')">
                        <i class="el-icon-place"></i>
                        快速选择
                    </Button>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.stand_pool_deal')">
                    <RadioGroup size="large">
                        <el-radio @change="sDealChange" v-model="ProxyData.SparePool.Deal" label="TCP">TCP</el-radio>
                        <el-radio @change="sDealChange" v-model="ProxyData.SparePool.Deal" label="SSL">SSL</el-radio>
                    </RadioGroup>
                </FormItem>
            </Col>
            <Col :xs="24" :md="6" :lg="6">
                <FormItem :label="$t('lang.proxy.conn_test')">
                    <!-- 请输入或选择一个矿池地址 -->
                    <beat-ani v-if="spStatus == 'ping'"></beat-ani>
                    <span class="info" v-show="spStatus == 'wait'">
                        等待输入
                    </span>
                    <span v-show="spStatus == 'ok'" class="success">
                        <Icon type="md-checkmark-circle-outline" />{{$t('lang.proxy.conn_test_ok')}}
                    </span>
                    <span v-show="spStatus == 'error'" class="error">
                        <Icon type="md-close" />{{$t('lang.proxy.conn_test_no')}}
                    </span>
                </FormItem>
            </Col>
        </Row>
        <select-preset-pool ref="select-preset-pool"></select-preset-pool>
    </Form>
</template>
<script>
import ProxyData from '@/components/Proxy/data.js'
import BeatAni from '@/components/BeatAni.vue'
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            pStatus: 'wait', // 'wait, ping, error, ok'
            spStatus: 'wait',
            ProxyData: ProxyData.data,
            RuleValidate: ProxyData.ruleValidate,
            options: [
                // {
                //     label: '自定义矿池',
                //     options: [
                //         {
                //             label: '鱼池',
                //             value: 'www.baidu1.com'
                //         }
                //     ]
                // },
                // {
                //     label: '预置矿池',
                //     options: [
                //         {
                //             label: '猫池',
                //             value: 'www.baidu.com'
                //         }
                //     ]
                // }
            ]
        }
    },
    components: {
        BeatAni
    },
    computed:{
        ...mapGetters('meta', {
            getCurrency: 'getCurrency',
            getSortCurrency: 'getSortCurrency',
            getMob: 'getMob'
        })
        // showE9() {
        //     let res = false;
        //     let proxyCoin = this.ProxyData.proxyCoin;
        //     let category = this.getCurrency[proxyCoin];

        //     if (category == 'ethhash' || category == 'ethhash2d') {
        //         res  
        //     }

        //     return res;
        // }
    },
    mounted() {
    },
    methods:{
        // poolChange($e) {
        //     this.ProxyData.ProxyPool.Pool = $e.target.value
        // },
        // sparePoolChange($e) {
        //     this.ProxyData.SparePool.Pool = $e.target.value
        // },
        selectPreProxy(target) {
            let that = this;

            if (this.ProxyData.ProxyCoin) {
                this.$refs['select-preset-pool'].show(this.ProxyData.ProxyCoin, (row) => {
                    if (target == 'proxy') {
                        that.ProxyData.ProxyPool.Pool = row.address
                        that.ProxyData.ProxyPool.Deal = row.deal

                        that.poolURLChange()
                        that.dealChange()
                    } else if (target == 'spare') {
                        that.ProxyData.SparePool.Pool = row.address
                        that.ProxyData.SparePool.Deal = row.deal

                        that.sPoolURLChange()
                        that.sDealChange()
                    }
                }) 
            } else {
                this.$Message.warning("请先选择要创建的代理币种。")
            }
        },
        transName(v) {
            let res = v;

            if (v == 'NGINX') {
                res = '纯转发'
            } else if (v == 'KAS(ks3)') {
                res = 'KAS(ks3, ks3l, ks3m)'
            } else if (v == 'KASPA') {
                res = 'KAS(GPU)'
            }

            return res
        },
        mobTips() {
            let mobURL = this.getMob.mobURL;

            this.$Swal.fire({
                icon: 'warning',
                title: '请注意',
                html: `
                    ** 本地挖矿内核请注意版本 **
                    <br><br>
                    DNX官方已宣布关闭mallob验证, 无需再配置mallob地址, 请使用最新的挖矿内核进行挖矿。
                    <br><br>
                    虽然已关闭验证, 目前看起来官方并未按照公布时间关闭验证, 请等待矿池以及官方后续通知, 在此之前请不要开启抽水,程序依旧保留了mallob的代理转发, 您的mallob地址为：
                    <br><br>
                    ${mobURL}
                    <br><br>
                    如果您尚未升级，请确保将您的矿工升级到最新版本（OZM 1.2.5 或 SRB 2.3.9），官方从 10 月 21 日 04:00 开始停止对旧版本的支持（目前看起来并未生效）。感谢您的支持！
                    <br><br>
                    Nvidia: <a href="https://github.com/OneZeroMiner/onezerominer/releases" target="_blank">OneZeroMiner</a><br>
                    AMD: <a href="https://github.com/doktor83/SRBMiner-Multi/releases" target="_blank">RBMiner</a><br> 
                    请不要使用DynexSolve内核进行挖掘。
                `
            })
            // this.$Swal.fire({
            //     icon: 'warning',
            //     title: '请注意',
            //     html: `
            //         DNX目前仅允许纯转发, 无法抽水, 即便配置也无法生效！！！
            //         <br><br>
            //         DNX挖矿内核需要配置mallob地址, 您的mallob地址为：
            //         <br><br>
            //         ${mobURL}
            //         <br> <br>
            //         请在挖矿内核mallob地址项中配置以上地址, 如需修改监听端口号, 请运行安装脚本选择修改mallob端口。<br><br>
            //         支持的内核: <br>
            //         Nvidia: <a href="https://github.com/OneZeroMiner/onezerominer/releases" target="_blank">OneZeroMiner</a><br>
            //         AMD: <a href="https://github.com/doktor83/SRBMiner-Multi/releases" target="_blank">RBMiner</a><br> 
            //         请不要使用DynexSolve内核进行挖掘。<br><br>
            //         支持的矿池: <br>
            //         <a href="https://f2pool.zendesk.com/hc/zh-cn/articles/22582725659673" target="_blank">
            //             鱼池
            //         </a>、
            //         <a href="https://dynex.herominers.com/#how-to-mine-dynex-dnx" target="_blank">
            //             herominer
            //         </a>
            //     `
            // })
        },
        activeCoinChange(v) {
            if (v == 'NGINX') {
                this.$Swal.fire({
                    icon: 'warning',
                    title: '注意',
                    text: '创建纯转发端口, 此端口只对[端口号], [最大连接数], [端口配置], [代理矿池地址、协议] 这些配置生效, 其余配置均不生效, 不含数据统计及手续费等功能,此端口可以进入任意币种及型号的设备。'
                })
            } else if (v == 'DNX') {
                this.mobTips()
            } else if (v == 'LTC') {
                this.$Swal.fire({
                    icon: 'warning',
                    title: '注意',
                    text: 'LTC抽水请不要抽到鱼池, 最好的选择是跨池抽到viabtc或是其他池子, 跨池抽水不影响客户, 鱼池会严重抽不够, 矿池原因。'
                })
            } else if (v == 'ALPH') {
                this.$Swal.fire({
                    icon: 'warning',
                    title: '注意',
                    text: '注意, 建议在herominer挖掘alph, 其他矿池存在diff问题, 如果要跑其他池子建议使用nginx纯转发。'
                })
            } else if (v == 'RXD') {
                this.$Swal.fire({
                    icon: 'warning',
                    title: '注意',
                    text: '请不要使用bzminer挖掘RXD!!!'
                })
            }

            if (v != 'BTC' && v != 'LTC' && v != 'BCH') {
                this.ProxyData.setting.op = 0
            }
            // } else if (v == 'BTC') {
            //     this.$Swal.fire({
            //         icon: 'warning',
            //         title: '注意',
            //         text: '【阿瓦隆】或【神马】的(部分)机型, 无法跨池抽水, 会导致算力下降相当高, 如果您接入的有此类机型, 请不要跨池抽水！！！ -通常情况下, 请不要跨池抽水。'
            //     })
            // }
        },
        checked() {
            this.ProxyData.ProxyPool.Pool = this.$Func.dSpace(this.ProxyData.ProxyPool.Pool)
            this.ProxyData.SparePool.Pool = this.$Func.dSpace(this.ProxyData.SparePool.Pool)

            return this.$refs['validateRef'].validate()
        },
        checkURL(url, deal, s) {
            if (!url) {
                return
            }

            let tUrl = url.split("//");

            if (tUrl.length > 1) {
                tUrl = tUrl[1]
            } else {
                tUrl = tUrl[0]
            }

            if (s == 0) {
                this.pStatus = 'ping'

                this.axios({
                    url: this.$API.ping,
                    method: 'post',
                    data: {
                        address: tUrl,
                        conn_type: (deal == 'TCP') ? 0 : 1
                    }
                }).then(res => {
                    if (res.status == 200) {
                        this.pStatus = 'ok'
                    } else {
                        this.pStatus = 'error'
                    }
                }).catch(e => {
                    this.pStatus = 'error'
                })
            } else {
                this.spStatus = 'ping'

                this.axios({
                    url: this.$API.ping,
                    method: 'post',
                    data: {
                        address: tUrl,
                        conn_type: (deal == 'TCP') ? 0 : 1
                    }
                }).then(res => {
                    if (res.status == 200) {
                        this.spStatus = 'ok'
                    } else {
                        this.spStatus = 'error'
                    }
                }).catch(e => {
                    this.spStatus = 'error'
                })
            }
        },
        poolURLChange() {
            let extract = this.ProxyData.Extract;
            let url = this.ProxyData.ProxyPool.Pool;
            let deal = this.ProxyData.ProxyPool.Deal;

            extract.forEach(item => {
                item.Pool = url
                item.Deal = this.ProxyData.ProxyPool.Deal
            })

            this.checkURL(url, deal, 0)
        },
        sPoolURLChange() {
            let url = this.ProxyData.SparePool.Pool;
            let deal = this.ProxyData.SparePool.Deal;

            this.checkURL(url, deal, 1)
        },
        dealChange(v) {
            let extract = this.ProxyData.Extract;
            let url = this.ProxyData.ProxyPool.Pool;
            let deal = this.ProxyData.ProxyPool.Deal;

            extract.forEach(item => {
                if (this.ProxyData.ProxyPool.Pool == item.Pool) {
                    item.Deal = this.ProxyData.ProxyPool.Deal
                }
            })
            
            this.checkURL(url, deal, 0)
        },
        sDealChange(v) {
            let url = this.ProxyData.SparePool.Pool;
            let deal = this.ProxyData.SparePool.Deal;
            
            if (url) {
                this.checkURL(url, deal, 1)
            }
        },
        // 新建端口时的延迟检查
        // 新建之前点编辑或者新建过其他poolurl的时候, 这里会带上那个url, 这里做个检查或者状态重置
        newDealChange() {
            let purl = this.ProxyData.ProxyPool.Pool;
            let surl = this.ProxyData.SparePool.Pool;

            if (purl) {
                this.dealChange()
            } else {
                this.pStatus = 'wait'
            }

            if (surl) {
                this.sDealChange()
            } else {
                this.spStatus = 'wait'
            }
        }
    }
}
</script>

<style lang="scss">
    .default-config-class .ivu-select-dropdown,
    .ivu-select-dropdown{
        max-height: 500px;
    }

    .port-coin-select{
        margin-bottom: 0;

        .ivu-select-input{
            color: var(--color-1)
        }
    }

    @media screen and (max-width: $mdWidth){
        .port-coin-select{
            margin-bottom: 24px;
        }
    }

    .fixed-select-coin-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
            display: block;
            // width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        span{
            font-size: 14px;
        }
        .engine{
            font-size: 12px;
            flex: 1;
            text-align: right;
        }
    }
</style>

<style lang="scss" scoped>
    .success{
        color: var(--color-9);
    }

    .error{
        color: var(--color-5);
    }

    ._engine{
        display: block;
        white-space: nowrap;
        overflow: hidden;
        // text-overflow: ellipsis;
        max-width: 65px;
        margin-left: auto;
        font-size: 12px!important;
    }

    .s-tit{
        color: var(--font-color-1);
        margin-bottom: -10px;

        .question{
            color: var(--yellow);
        }
    }

    .mallob-title::before{
        content: '*';
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 12px;
        color: #ed4014;
    }

    .mallob-p{
        color: var(--color-7);
        cursor: pointer;
        display: flex;
        align-items: center;

        i{
            margin-right: 5px;
        }

        span{
            text-decoration: underline;
        }
    }
</style>
