<template>
    <div class="wallet-alert">
        <Modal
            class-name="wallet-alert-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="wallet"
            :footer-hide="true"
        >
            <div v-loading="loading">
                <p>请选择要对{{ wallet }}进行的操作。</p>
                <div style="margin-top: 10px;">
                    <el-link type="danger" @click="addBan" style="margin-right: 10px;">添加至黑名单</el-link>
                    <el-link type="success" @click="free" style="margin-right: 10px;">从黑名单解除</el-link>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>

// import TermCon from '@/components/TermCon.vue'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            wallet: '-',
            callback: null
        }
    },
    components: {
        // TermCon
    },
    computed: {

    },
    mounted() {
    },
    methods: {
        show(wallet, callback) {
            this.callback = null;
            if (!wallet) {
                return
            }
            
            this.visable = true
            this.wallet = wallet

            if (callback) {
                this.callback = callback
            }
        },
        hide() {
            this.visable = false
        },
        addBan() {
            let wallet = this.wallet;

            this.axios({
                method: 'post',
                url: this.$API.banWallet,
                data: {
                    ips: [wallet]
                }
            }).then(res => {
                if (res.status == 200) {
                    this.$Message.success('添加黑名单成功! 可在 设置 -> 钱包黑名单中查看或解除。')
                    this.callback && this.callback()

                    this.hide()
                } else {
                    this.$Message.success('添加黑名单失败！')
                }
            })
        },
        free() {
            let wallet = this.wallet;

            this.axios({
                method: 'post',
                url: this.$API.deleteBanWallet,
                data: {
                    ips: [wallet]
                }
            }).then(res => {
                if (res.status == 200) {
                    this.$Message.success('解除黑名单成功！')
                    this.callback && this.callback()

                    this.hide()
                } else {
                    this.$Message.success('解除黑名单失败！')
                }
            })
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .wallet-alert-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .wallet-alert-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.wallet-alert-model{
    margin: 0 auto;
    width: 50%;
    max-width: 400px;
    overflow: hidden!important;
    overflow-y: auto!important;
}
</style>
