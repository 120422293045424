<template>
    <div>
        <Modal
            class-name="proxy-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            :title="title"
            :footer-hide="true"
        >
        <!-- <modal name="my-first-modal" class="proxy-model"> -->
            <div slot="header">
                <div class="title">
                    <Icon type="ios-add" :size="25"/>
                    <span>{{ title }}</span>
                </div>
            </div>
            <div class="proxy-model-body" v-loading="modelLoading">
                <div class="proxy-content">
                    <Divider orientation="left">{{ $t('lang.proxy.t_deal') }}</Divider>
                    <deal-config></deal-config>
                    <Divider orientation="left">{{ $t('lang.proxy.t_default') }}</Divider>
                    <default-config ref="defaultRef"></default-config>
                    <Divider orientation="left" v-show="ProxyData.ProxyCoin != 'NGINX'">{{ $t('lang.proxy.t_fee') }}</Divider>
                    <pump-config ref="pumpRef" v-show="ProxyData.ProxyCoin != 'NGINX'"></pump-config>
                    <Divider orientation="left" v-show="ProxyData.ProxyCoin != 'NGINX'">{{ $t('lang.proxy.t_replace') }}</Divider>
                    <replace-wallet-config v-show="ProxyData.ProxyCoin != 'NGINX'" ref="replaceRef"></replace-wallet-config>
                    <Divider orientation="left" v-show="ProxyData.ProxyCoin != 'NGINX'">其他配置</Divider>
                    <auth-config ref="authRef" v-show="ProxyData.ProxyCoin != 'NGINX'"></auth-config>
                    <div class="opt-group">
                        <Button v-if="mode == 'add'" @click="submitCreate" size="large" class="save-btn" type="primary">{{ $t('lang.proxy.create_proxy') }}</Button>
                        <Button v-if="mode == 'edit'" @click="submitEdit" size="large" class="save-btn" type="primary">{{ $t('lang.update_config') }}</Button>
                        <Button v-if="mode == 'clone'" @click="submitEdit" size="large" class="save-btn" type="primary">复制端口</Button>
                        <Button @click="hide" size="large">{{ $t('lang.cancel') }}</Button>
                    </div>
                </div>
            </div>
        </Modal>
        <!-- </modal> -->
    </div>
</template>
<script>
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'
import ProxyData from '@/components/Proxy/data.js'
import DealConfig from '@/components/Proxy/DealConfig.vue'
import DefaultConfig from '@/components/Proxy/DefaultConfig.vue'
import PumpConfig from '@/components/Proxy/PumpConfig.vue'
// import ConnectConfig from '@/components/Proxy/ConnectConfig.vue'
import ReplaceWalletConfig from '@/components/Proxy/ReplaceWalletConfig.vue'
// import CompensationConfig from '@/components/Proxy/CompensationConfig.vue'
import AuthConfig from '@/components/Proxy/AuthConfig.vue'

export default {
    data() {
        return {
            visable: false,
            ProxyData: ProxyData.data,
            mode: 'add',
            modelLoading: false,
            editId: '',
            showPump: true
        }
    },
    components: {
        DealConfig,
        DefaultConfig,
        PumpConfig,
        // ConnectConfig,
        ReplaceWalletConfig,
        // CompensationConfig
        AuthConfig
    },
    computed:{
        ...mapGetters('meta', {
            getCurrency: 'getCurrency'
        }),
        title() {
            let res = '-'

            if (this.mode == 'add') {
                res = '添加新代理'
            } else if (this.mode == 'edit') {
                res = ' 编辑代理'
            } else if (this.mode == 'clone') {
                res = '复制代理'
            }


            return res
        }
    },
    mounted() {
        
    },
    methods:{
        ...mapActions('port', [
            'updatePorts'
        ]),
        show(mode, editId, currency) {
            this.$refs.pumpRef.resetActive()
            ProxyData.reset();
            
            if (mode == 'edit') {
                this.mode = 'edit'
                this.modelLoading = true
                this.editId = editId

                this.axios({
                    url: this.$API.getPortById.replace('{id}', editId),
                    method: 'get'
                }).then(res => {
                    let merge = {}
                    if (res.status == 200) {
                        // 服务器数据转化为本地数据
                        merge = res.data.server
                        merge.wallets = res.data.wallets

                        ProxyData.transServeToData(res.data);

                        this.$refs['defaultRef'].dealChange()
                        this.$refs['defaultRef'].sDealChange()
                    } else {
                        this.$Message.error(this.$t('lang.get_config_error'));

                        this.hide()
                    }

                    this.modelLoading = false   
                })
            } else if (mode == 'clone') {
                this.mode = 'clone'
                this.modelLoading = true
                this.editId = editId

                this.axios({
                    url: this.$API.getPortById.replace('{id}', editId),
                    method: 'get'
                }).then(res => {
                    let merge = {}
                    if (res.status == 200) {
                        // 服务器数据转化为本地数据
                        merge = res.data.server
                        merge.wallets = res.data.wallets

                        // 复制端口, 将walletid清空
                        let serveData = res.data;

                        // 将端口清空
                        serveData.server.port = null;
                        
                        let serveWallets = serveData.wallets;
                        for (let i = 0; i < serveWallets.length; i++) {
                            let wallet = serveWallets[i];
                            if (wallet.id) {
                                delete wallet.id
                            }
                        }
                        ProxyData.transServeToData(serveData);
                        

                        this.$refs['defaultRef'].dealChange()
                        this.$refs['defaultRef'].sDealChange()
                    } else {
                        this.$Message.error(this.$t('lang.get_config_error'));

                        this.hide()
                    }

                    this.modelLoading = false   
                })
            } else {
                this.$refs['defaultRef'].newDealChange()
                this.mode = 'add'

                if (currency) {
                    this.ProxyData.ProxyCoin = currency
                }
            }

            this.visable = true
        },
        hide() {
            this.visable = false
        },
        submitEdit() {
            let that = this;
            this.autoClean(true);

            Promise.all([
                this.$refs.defaultRef.checked(),    
                // this.$refs.connectRef.checked(),
                this.$refs.pumpRef.checked(),
                this.$refs.replaceRef.checked(),
                // this.$refs.compensationRef.checked()
            ]).then(res => {
                return new Promise((resolve, reject) => {
                    if (res.indexOf(false) > -1) {
                        reject(1)
                    } else {
                        resolve(true)
                    }
                })
            }).then(res => {
                return new Promise((resolve, reject) => {
                    resolve(true)
                })
            }).then(res => {
                let url = that.$API.editPort.replace('{id}', that.editId);

                // 如果是克隆端口, 则通过新建端口接口去添加
                if (this.mode == 'clone') {
                    url = that.$API.createPort
                }

                let serveData = ProxyData.transDataToServe(that.ProxyData, true);
                // 将所选币种对应的算法插入进去
                serveData.category = this.getCurrency[serveData.currency].category
                
                that.axios({
                    method: 'post',
                    url,
                    data: serveData
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data == -1) {
                            this.$Message.warning('创建失败！！所选币种当前版本不支持, 如需创建请更新程序。')
                        } else {
                            that.$Message.success(this.$t('lang.save_success'))

                            that.updatePorts().then(() => {
                                that.$router.push({
                                    path: `/system/index/portinside?id=${res.data}`,
                                });

                                that.hide()
                            })
                        }
                    } else {
                        that.$Message.error(this.$t('lang.save_error'))
                    }
                })
            }).catch(e => {
                if (e == 1) {
                    this.$Swal.fire({
                        icon: 'error',
                        title: this.$t('lang.proxy.create_error'),
                        text: this.$t('lang.proxy.create_config_error')
                    })
                } else if (e == 2) {
                    this.$Message.error(this.$t('lang.save_error_1'))
                }
            })
        },
        submitCreate() {
            let that = this;
            this.autoClean();

            Promise.all([
                this.$refs.defaultRef.checked(),    
                // this.$refs.connectRef.checked(),
                this.$refs.pumpRef.checked(),
                this.$refs.replaceRef.checked(),
                // this.$refs.compensationRef.checked()
            ]).then(res => {
                if (res.indexOf(false) > -1) {
                    this.$Swal.fire({
                        icon: 'error',
                        title: this.$t('lang.proxy.create_error'),
                        text: this.$t('lang.proxy.create_config_error')
                    })
                } else {
                    let serveData = ProxyData.transDataToServe(this.ProxyData);

                    // 将所选币种对应的算法插入进去
                    serveData.category = this.getCurrency[serveData.currency].category

                    that.axios({
                        method: 'post',
                        url: that.$API.createPort,
                        data: serveData
                    }).then(res => {
                        if (res.status == 200) {
                            if (res.data == -1) {
                                this.$Message.warning('创建失败！！所选币种当前版本不支持, 如需创建请更新程序。')
                            } else {
                                that.$Message.success(this.$t('lang.create_success'))

                                that.updatePorts().then(() => {
                                    that.$router.push({
                                        path: `/system/index/portinside?id=${res.data}`,
                                    });

                                    that.hide()
                                })
                            }
                        } else{
                            that.$Message.error(this.$t('lang.error'))
                        }
                    })
                }
            })
        },
        // 过滤一些敏感数据及输入规则
        autoClean(isEdit) {
            // 清理掉对应字段的空格
            this.ProxyData.ProxyPool.Pool && (this.ProxyData.ProxyPool.Pool = this.ProxyData.ProxyPool.Pool.replace(/\s*/g, ""));
            this.ProxyData.SparePool.Pool && (this.ProxyData.SparePool.Pool = this.ProxyData.SparePool.Pool.replace(/\s*/g, ""));
            this.ProxyData.ConnectMode.Pool.UnifyWallet && (this.ProxyData.ConnectMode.Pool.UnifyWallet = this.ProxyData.ConnectMode.Pool.UnifyWallet.replace(/\s*/g, ""));
            this.ProxyData.ConnectMode.Pool.UnifyDeviceName && (this.ProxyData.ConnectMode.Pool.UnifyDeviceName = this.ProxyData.ConnectMode.Pool.UnifyDeviceName.replace(/\s*/g, ""));
            // this.ProxyData.ReplaceWallet.ByWallets && (this.ProxyData.ReplaceWallet.ByWallets = this.ProxyData.ReplaceWallet.ByWallets.replace(/\s*/g, ""));
            this.ProxyData.ReplaceWallet.ToWallets && (this.ProxyData.ReplaceWallet.ToWallets = this.ProxyData.ReplaceWallet.ToWallets.replace(/\s*/g, ""));

            for (let i = 0; i < this.ProxyData.Extract.length;) {
                let item = this.ProxyData.Extract[i];

                item.Wallet && (item.Wallet = item.Wallet.replace(/\s*/g, ""));
                item.Pool && (item.Pool = item.Pool.replace(/\s*/g, ""));
                item.DeviceName && (item.DeviceName = item.DeviceName.replace(/\s*/g, ""));

                // 编辑状态不要清空, 因为后端没清空, 否则不一致
                if (!isEdit) {
                    // 删除没有设置抽水比例的钱包
                    if (i != 0) {
                        if (!item.Percent) {
                            // this.ProxyData.Extract.splice(i, 1);
                            this.$refs.pumpRef.deleteActive(i)
                        } else {
                            i++
                        }
                    } else {
                        i++
                    }   
                } else {
                    i++
                }
            }
        }
    }
}
</script>

<style lang="scss">
.proxy-model{
    .ivu-modal-body{
        padding: 0;
    }
}

</style>

<style lang="scss">
@media screen and (max-width: $hideMenuWidth){
    .proxy-model{
        width: 100%!important;
    }
}

.proxy-model{
    margin: 0 auto;
    max-width: 1150px;
    width: 80%;
    overflow: hidden!important;
    overflow-y: auto!important;

    .input-btn-group .ivu-form-item-content{
        display: flex;

        input{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        button{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .proxy-model-body{
        width: 100%;
        height: 100%;
        padding: 20px 0;
    }

    .ivu-radio-wrapper{
        margin-right: 20px;
    }

    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--font-color-1);
    }

    .proxy-content{
        max-width: 920px;
        margin: 0 auto;
        padding: 0 10px;

        .item-title{
            font-size: 18px;
            color: var(--font-color-1);
            margin-bottom: 20px;
        }

        .insert-txt{
            margin-bottom: 10px;
            font-size: 12px;
            color: var(--font-color-1);
        }

        .item-card{
            display: flex;
            flex-wrap: nowrap;
            // flex-direction: column;
            flex-direction: row;
            /* align-content: center; */
            align-items: center;
            // min-height: 142px;
            justify-content: space-between;

            .el-radio__label{
                color:  var(--font-color-1);
                font-size: 15px;
                font-weight: bold;
            }

            img{
                display: block;
                width: 25px;
                height: 25px;
            }

            h3{
                color: var(--font-color-1);
            }

            p{
                font-size: 12px;
                line-height: 18px;
                // height: 54px;
                margin-bottom: 15px;
                flex: 1;
            }

            .ivu-radio-wrapper{
                margin-right: 0;
                .ivu-radio{
                    margin-right: 0;
                }
            }
        }

        .wallet-item-list{
            max-height: 232px;
            overflow: hidden;
            overflow-y: auto;
        }

        .item-wallet{
            // border: 1px solid rgba(0, 0, 0, 0);
            border-radius: 3px;
            padding: 5px 0;
            cursor: pointer;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .item-wallet-icon{
                margin-right: 10px;
                width: 40px;
                height: 40px;
                background-color: var(--bg-5);
                color: var(--color-1);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                transition: all .3s;
            }

            .item-wallet-right{
                width: calc(100% - 53px);

                .item-wallet-right-title{
                    font-size: 15px;
                    font-weight: 500;
                    transition: all .3s;
                }

                .item-wallet-right-address{
                    font-size: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .item-wallet:hover{
            .item-wallet-icon{
                background-color: var(--bg-2);
                color: var(--color-2);
            }

            .item-wallet-right{
                .item-wallet-right-title{
                    color: var(--bg-2);
                }
            }
        }

        .item-wallet.active{
            .item-wallet-icon{
                background-color: var(--bg-2);
                color: var(--color-2);
            }

            .item-wallet-right{
                .item-wallet-right-title{
                    color: var(--bg-2);
                }
            }
        }

        .item-add-wallet{
            margin-bottom: 10px;
        }

        .wallet-right-title{
            font-size: 20px;
            color: var(--font-color-1);
            margin-bottom: 10px;
        }

        .save-btn{
            margin-right: 20px;
        }

        .opt-group{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        // .select-coin-item{
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-start;
        //     img{
        //         display: block;
        //         // width: 20px;
        //         height: 20px;
        //         margin-right: 10px;
        //     }
        //     span{
        //         font-size: 14px;
        //     }
        //     .engine{
        //         font-size: 12px;
        //         flex: 1;
        //         text-align: right;
        //     }
        // }
    }

    .no-text{
        .el-radio__label{
            display: none;
        }
    }
}
</style>
