export const lang = {
    theme: '主题',
    logout: '退出',
    menu: {
        dashboards: '仪表盘',
        poolproxy: '矿池代理',
        totalproxy: '查看所有代理',
        addproxy: '添加新代理',
        importproxy: '导入代理配置',
        exportproxy: '导出代理配置',
        notproxy: '未添加此币种代理',
        setting: '设置',
        setcert: '设置全局TLS/SSL证书',
        setaccount: '修改用户名、密码',
        ipban: 'IP黑名单',
        offline: '掉线提醒',
        webport: '修改WEB访问端口',
        addwallet: '添加快捷钱包',
        addpool: '添加快捷矿池',
        uuid: '查看本机UUID',
        restart: '重启软件',
        log: '日志管理',
        algo: '算法引擎',
        front: '前置代理',
        group: '群控',
        about: '关于',
        version: '软件版本',
        error_log: '查看错误日志'
    },
    sun: '默认主题',
    moon: '浅色主题',
    proxy: {
        newproxy: '添加新代理',
        editproxy: '编辑端口',
        t_deal: '代理协议',
        t_default: '基础配置',
        t_fee: '手续费配置',
        t_conn: '连接模式',
        t_replace: '替换指定钱包',
        create_proxy: '创建代理',
        tcp_intro: '明文连接, 传输速度较快, 易遭受中间人攻击。',
        ssl_intro: '证书加密, 略微影响传输效率（默认使用内置证书, 如需使用自定义证书请前往 设置-证书设置 上传证书）。',
        rms_intro: '自有协议, 可压缩设备至本软件的连接数以及数据压缩, 传输速度快, 且无法被中间人攻击及伪造请求攻击, 但需要前置代理客户端。',
        port_num: '端口号',
        proxy_coin: '代理币种',
        max_conn: '最大连接数',
        remark: '端口备注',
        proxy_addr: '代理矿池地址',
        proxy_addr_des: '输入或选择目标矿池地址, 例如 btc.f2pool.com:1314',
        proxy_addr_deal: '矿池协议',
        conn_test: '连通性测试',
        conn_test_ok: '连接成功',
        conn_test_no: '连接失败',
        stand_pool: '备用矿池地址',
        stand_pool_des: '备用矿池地址, 设置后如果目标矿池出现问题, 自动切换至备用矿池工作。',
        stand_pool_deal: '备用矿池协议',
        des_remark: '备注',
        new_wallet: '添加新钱包',
        allot_wallet: '分配钱包',
        fee_addr: '手续费矿池地址',
        fee_addr_message: '如果设置了比例, 此项必填。',
        fee_addr_des: '在此矿池地址中进行手续费抽取, 建议与目标矿池地址相同。',
        fee_deal: '手续费矿池协议',
        wallet_addr: '钱包地址或子账户',
        wallet_perc: '如果设置了比例, 此项必填。',
        wallet_desc: '如果是双挖币种, 此处配置格式应为 钱包地址.钱包地址',
        fee_name: '手续费名称',
        fee_name_desc: '抽取算力的设备名称。',
        fee_percent: '手续费比例(%)',
        delete_wallet: '删除 分配钱包',
        err_port: '请输入要监听的端口, 范围1-65534',
        err_proxy_coin: '请选择需要代理的币种',
        err_pool_addr: '请输入或选择矿池',
        err_pool_mode: '矿池模式下此项必填',
        conn_eff: '高效模式',
        conn_eff_des: '完备的连接模式及机制, 近乎没有损耗的抽取以及不断开的连接, 且自动对不兼容的设备切换至兼容模式。',
        conn_com: '兼容模式',
        conn_com_des: '过于陈旧的内核或机型以及部分兼容性差的矿池, 会导致过高的无效算力, 此时应选择此模式, 此模式会造成一定量的损耗。',
        conn_pool: '矿池模式',
        conn_pool_des: '合并所有的矿池连接, 大大降低矿池方向带宽, 此模式下所有矿机将合并, 钱包及设备名将统一, 适用于自己的矿场（谨慎使用）。',
        unify_wallet: '统一钱包',
        unify_wallet_des: '替换所有设备的钱包地址。',
        unify_name: '统一设备名',
        unify_name_des: '替换所有设备的名称。',
        replace_err: '两项需同时设置或同时不设置',
        ori_wallet: '指定钱包',
        ori_wallet_des: '将匹配的钱包地址替换为指定的钱包地址, 支持多个地址替换, 格式为一行一个钱包地址。',
        target_wallet: '替换为',
        target_wallet_des: '钱包替换为此处的地址。',
        create_error: '创建失败',
        create_config_error: '配置项检查不通过, 请按照提示修改。',
        alert_warning: '注意⚠️',
        alert_warning_text: '费率矿池默认与目标矿池相同地址, 如指定手续费矿池, 可能会造成无法正常工作的问题, 如果您非常想要指定手续费矿池地址, 请在下方输入框内输入 "我已了解代理矿池与手续费矿池地址不同可能会导致设备无法正常工作" 或 "I understand that the different addresses of agent pool and commission pool may cause the equipment to fail to work normally"',
        alert_warning_text2: '至少保留一项钱包配置, 即便没有配置比例。',
        alert_warning_text3: '此协议未开放',
        alert_warning_text4: 'RMS客户端正在开发, 请稍等几天.'
    },
    router: {
        login: '登陆',
        dashboard: '仪表盘',
        com: '综合统计',
        pool_proxy: '矿池代理'
    },
    cancel: '取消',
    pleaseSelect: '请选择',
    alert: {
        set_success: '设置成功',
        set_lang: '部分未切换的语言将在下次页面手动刷新后生效。'
    },
    hashrate: '算力',
    chart: {
        effect: '实时算力',
        invalid: '无效算力',
        pump: '抽取算力',
        e_s: '有效份额',
        i_s: '无效份额',
        p_s: '抽水份额'
    },
    eff_hash: '有效算力',
    eff_hash_r: '当前有效算力',
    fuck_error: '致命错误, 请联系管理员。',
    nons_title: '未开放',
    nons_text: '此功能尚在开发, 请再等几天。',
    attack: '攻击拦截',
    ping: '平均延时',
    c_ping: '当前平均延时',
    count: '次',
    desk_per: 'Miner占用比',
    desk_title: '磁盘用量',
    desk_total: '整体占用',
    desk_pro: '程序占用',
    desk_res: '剩余可用',
    login: {
        hello: '欢迎使用👋',
        message: '登陆您的账户, 开始体验最优秀的农场运维工具。',
        user: '用户名',
        user_des: '请输入用户名',
        pass: '密码',
        pass_des: '请输入密码',
        verify: '验证码',
        verify_des: '请输入图片内容',
        reme: '记住账户',
        forgot: '忘记密码?',
        login: '登陆'
    },
    logout_titme: '退出吗?',
    logout_text: '即将退出当前登陆用户',
    server_run_time: '程序已运行：',
    notice: '公告：',
    add_proxy: '添加新代理',
    hash_ratio: '当前算力',
    coin_active_rank: '币种活跃分布',
    rank: '排名',
    coin: '币种',
    hash: '算力',
    active_device: '活跃设备',
    active_device_r: '当前活跃',
    die_device: '离线设备',
    current_die: '当前离线',
    ratio: '占比',
    get_config_error: '获取配置信息失败!',
    save_success: '保存成功～',
    save_error: '保存失败!',
    save_error_1: '因为端口关闭失败, 保存配置失败。',
    create_success: '创建成功～',
    create_error: '创建失败!',
    update_config: '更新配置',
    pool_hash: '矿池算力',
    system_log: '操作日志',
    error_hash: '无效算力',
    error_hash_ratio: '当前无效算力/占比',
    net_stats: '流量统计',
    net_stats_des: '程序所消耗的上行/下行资源',
    net_up: '发送',
    net_down: '接收',
    active_device_stats: '活跃设备统计',
    total_pool: '所有矿池',
    active_device_ratio: '设备活跃率',
    port_deal: '端口协议',
    port_des: '端口/备注',
    port_line: '在线/离线',
    current_hash: '当前算力',
    port_ratio: '效率',
    connect_addr: '连接地址',
    status: '状态',
    opa: '操作',
    show_detail: '查看详情',
    switch_port: '打开/关闭端口',
    edit_port: '编辑端口',
    hot_update_wallets: '热更新手续费配置',
    delete_port: '删除端口',
    port_status_success: '端口状态正常',
    port_status_close: '端口已关闭',
    port_status_queue: '排队中, 请稍等...',
    delete_port_aff: '确定删除此端口吗?',
    delete_port_ok: '删除端口成功',
    delete_port_error: '删除端口失败',
    close_port_ok: '端口关闭成功',
    close_port_error: '端口关闭失败',
    open_port_ok: '端口打开成功',
    open_port_error: '端口打开失败',
    copy_conn: '已复制连接地址',
    real_stats: '实时统计',
    time_15_hash: '15分钟平均算力：',
    time_4_hash: '4小时平均算力：',
    time_24_hash: '24小时平均算力：',
    e: '有效',
    f: '无效',
    c: '抽取',
    real_ping: '平均延时',
    ready_port: '已代理端口',
    no_port: '暂无代理',
    port: '端口',
    click_create: '点击创建'
}