import axios from 'axios'
import CONFIG from '@/script/config.js'
import i18n from '@/locales/i18n'
import Swal from 'sweetalert2'

const $CONFIG = CONFIG.CONFIG

const state = () => ({
    local: {
        zh: 'zh-EN',
        theme: 'dark',
        chart3select: 'ETC',
        Authorization: '',
        loginfo: '',
        memory: false,
        walletPage: false,
        foundIndex: 0,
        listTab: 0,
        portListActiveSort: 'port',
        portListPageSie: 30,
        portListSortDir: 'ascending',
        defaultAves1: 900,
        defaultAves2: 86400,
        hideDeProxyCoin: false,
        hideActiveCoin: false,
        hideLeftCard: false
    }
})

const getters = {
    getLocal: (state, getters, rootState) => {
        return state.local
    }
}

const actions = {
    updateLocal({ state, commit }, args) {
        commit('setLocal', args)
    }
}

const mutations = {
    setLocal (state, args) {
        for (let key in args) {
            state.local[key] = args[key]

            // 如果是语言包设置, 则设置对应数据
            if (key == 'zh') {
                if (key != 'set_zh_hide_alert') {
                    i18n.locale = args[key]
                }

                if (!args['set_zh_hide_alert']) {
                    Swal.fire({
                        icon: 'success',
                        title: i18n.t('lang.alert.set_success'),
                        text: i18n.t('lang.alert.set_lang')
                    })
                }

                window.localStorage.setItem('zh', args[key]);
            } else if (key == 'theme') {
                window.localStorage.setItem('theme', args[key]);

                document.documentElement.removeAttribute('dark');
                document.documentElement.removeAttribute('light');
                document.documentElement.setAttribute(args[key], ''); 
            } else {
                window.localStorage.setItem(key, args[key]);
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}