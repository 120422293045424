var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Form',{ref:"validateRef",attrs:{"model":_vm.ProxyData,"label-position":"left","label-width":100,"rules":_vm.RuleValidate}},[_c('Row',{attrs:{"gutter":25,"type":"flex","justify":"start"}},[_c('Col',{attrs:{"xs":24,"md":24,"lg":24}},[_c('FormItem',{attrs:{"label":_vm.$t('lang.proxy.ori_wallet'),"prop":"ReplaceWallet.ByWallets","rules":{
                    required: _vm.byValidator, 
                    message: _vm.$t('lang.proxy.replace_err'), 
                    trigger: 'blur'
                }}},[_c('Input',{attrs:{"autosize":true,"type":"textarea","placeholder":_vm.$t('lang.proxy.ori_wallet_des')},model:{value:(_vm.ProxyData.ReplaceWallet.ByWallets),callback:function ($$v) {_vm.$set(_vm.ProxyData.ReplaceWallet, "ByWallets", $$v)},expression:"ProxyData.ReplaceWallet.ByWallets"}})],1)],1),_c('Col',{attrs:{"xs":24,"md":24,"lg":24}},[_c('FormItem',{attrs:{"label":_vm.$t('lang.proxy.target_wallet'),"prop":"ReplaceWallet.ToWallets","rules":{
                    required: _vm.toValidator, 
                    message: _vm.$t('lang.proxy.replace_err'), 
                    trigger: 'blur'
                }}},[_c('Input',{attrs:{"placeholder":_vm.$t('lang.proxy.target_wallet_des')},model:{value:(_vm.ProxyData.ReplaceWallet.ToWallets),callback:function ($$v) {_vm.$set(_vm.ProxyData.ReplaceWallet, "ToWallets", $$v)},expression:"ProxyData.ReplaceWallet.ToWallets"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }