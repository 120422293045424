import Vue from 'vue'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'zh-CN', // 语言标识
    messages: {
      'zh-CN': require('./zh-CN'), // 中文语言包
      'zh-EN': require('./zh-EN'), // 英文语言包
    }
});

export default i18n;