<template>
    <div class="select-preset">
        <Modal
            class-name="select-preset-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            title="选择快捷矿池地址"
            :footer-hide="true"
        >
            <div v-loading="loading">
                <p style="margin-bottom: 20px;">- 此处便捷的选择矿池地址, 您也可以在 首页菜单栏 - 设置 - 添加快捷矿池地址 处来添加自定义地址。</p>
                <el-tabs type="card" class="device-list-tab">
                    <el-tab-pane class="device-list-tab-panel" label="预置矿池">
                        <el-table
                            :data="poolInfo"
                            style="width: 100%"
                            :empty-text="`暂无${selectCoin}预置矿池, 请联系管理员添加, 预置矿池可热更新添加。`"
                        >
                            <el-table-column
                                prop="name"
                                label="备注"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <img class="name-coin" :src="$Func.getCoinImage(`${(scope.row.coin).toLowerCase()}`)" />
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                label="矿池地址"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="deal"
                                label="协议">
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-link type="success" @click="selectItem(scope)">
                                        选择
                                    </el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane class="device-list-tab-panel" label="自定义矿池">
                        <el-table
                            :data="presetPool"
                            style="width: 100%"
                            :empty-text="`暂无${selectCoin}自定义矿池。`"
                        >
                            <el-table-column
                                prop="name"
                                label="备注"
                            >
                                <template slot-scope="scope">
                                    <div class="pre-name">
                                        <img class="name-coin" :src="$Func.getCoinImage(`${(scope.row.coin).toLowerCase()}`)" />
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="address"
                                label="矿池地址"
                                :width="300"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="deal"
                                label="协议">
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-link type="success" @click="selectItem(scope)">
                                        选择
                                    </el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </Modal>
    </div>
</template>
<script>

import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            selectCoin: 'BTC',
            endPoint: {},
            presetPool: [
            ],
            callback: null,
            serverPool: [],
            poolInfo: []
        }
    },
    components: {
        // TermCon
    },
    computed: {
        ...mapGetters('meta', {
            getCurrency: 'getCurrency',
            getSortCurrency: 'getSortCurrency'
        })
    },
    mounted() {
        
    },
    methods: {
        show(coin, callback) {
            this.visable = true

            coin && (this.selectCoin = coin)
            callback && (this.callback = callback)

            this.getEndPoint()

            this.getPoolInfo()
        },
        getPoolInfo() {
            let that = this;

            this.axios({
                method: 'get',
                url: this.$API.getPoolInfo.replace('{id}', 1)
            }).then(res => {
                if (res && res.status == 200) {
                    if (res.data && res.data.length) {
                        that.serverPool = res.data

                        this.renderList()
                    }
                } else {
                    this.$Message.error('获取预置矿池地址失败。')
                }
            })
        },
        hide() {
            this.visable = false
        },
        getEndPoint() {
            let that = this;
            this.loading = true;

            this.axios({
                method: 'get',
                url: that.$API.getEndPoint
            }).then(res => {
                if (res.status == 200) {
                    that.endPoint = res.data

                    this.renderList();
                } else {
                    that.$Message.error('获取数据失败');
                }

                this.loading = false
            })
        },
        renderList() {
            let customPool = this.endPoint.customPool;
            let selectCoin = this.selectCoin;
            let serverPool = this.serverPool;

            if (customPool) {
                let presetPool = []

                for (let i = 0; i < customPool.length; i++) {
                    let item = customPool[i];

                    if (item.coin == selectCoin) {
                        presetPool.push(item)
                    }
                }

                this.presetPool = presetPool
            }

            if (serverPool) {
                let poolInfo = [];

                for (let i = 0; i < serverPool.length; i++) {
                    let item = serverPool[i];

                    if (item.brand == selectCoin) {
                        let insert = {
                            coin: item.brand,
                            name: item.content,
                            address: item.url
                        }

                        if (item.protocol == 0) {
                            insert.deal = 'TCP'
                        } else if (item.protocol == 1) {
                            insert.deal = 'SSL'
                        }

                        poolInfo.push(insert)
                    }
                }

                this.poolInfo = poolInfo
            }
        },
        activeCoinChange() {
            this.renderList()
        },
        selectItem(scope) {
            this.callback && this.callback(scope.row)

            this.hide()
        },
        transName(v) {
            let res = v;

            if (v == 'NGINX') {
                res = '纯转发'
            } else if (v == 'KAS(ks3)') {
                res = 'KAS(ks3, ks3l, ks3m)'
            } else if (v == 'KASPA') {
                res = 'KAS(GPU)'
            }

            return res
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .select-preset-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .select-preset-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.select-preset-model{
    margin: 0 auto;
    width: 50%;
    max-width: 700px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .el-tabs__item{
        font-size: 12px;
    }

    .pre-name{
        display: flex;
        align-items: center;

        .name-coin{
            height: 20px;
            display: block;
            margin-right: 5px;
        }
    }
}

.select-preset-select-coin-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
        display: block;
        // width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    span{
        font-size: 14px;
    }
    .engine{
        font-size: 12px;
        flex: 1;
        text-align: right;
    }
}
</style>
