<template>
    <Form @submit.native.prevent class="auth-config" ref="validateRef" :model="ProxyData" label-position="top">
        <Row :gutter="25" type="flex" justify="start">
            <Col :xs="24" :md="6" :lg="6">
                <FormItem label="强制下发成功份额" prop="Port">
                    <el-tooltip class="item" effect="dark" content="如果提交失败, 也强制给矿机返回成功, 这样一来矿机或内核显示的是百分百成功。" placement="bottom">
                        <div class="icon-item">
                            <el-switch v-model="ProxyData.setting.fr" :active-value="0" :inactive-value="1">
                            </el-switch>
                        </div>
                    </el-tooltip>
                </FormItem>
            </Col>
        </Row>
    </Form>
</template>
<script>
import ProxyData from '@/components/Proxy/data.js'

export default {
    data() {
        return {
            ProxyData: ProxyData.data,
            RuleValidate: ProxyData.ruleValidate
        }
    },
    computed:{
    },
    methods:{
        checked() {
            return this.$refs['validateRef'].validate()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.auth-config{
    .ivu-form-item{
        margin-bottom: 10px;
    }

    .icon-item{
        display: flex;
        align-items: center;

        .question{
            font-size: 16px;
            color: #ffb40b;
            margin-right: 10px;
        }
    }
}
</style>
