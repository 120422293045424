<template>
    <div class="wallet-ban">
        <Modal
            class-name="wallet-ban-model"
            v-model="visable"
            v-show="visable"
            :mask-closable="true"
            :width="100"
            title="IP黑名单"
            :footer-hide="true"
        >
            <div>
                <p v-show="loading">读取中...</p>
                <p v-show="!loading" class="title">此处展示已添加的钱包黑名单, 点击钱包进行相关操作（端口日志中点击对应钱包也可进行快捷操作）。</p>
                <div style="margin: 10px 0;">
                    <Input placeholder="请输入要禁止访问的钱包地址" v-model="currentWallet" size="small">
                        <Button size="small" slot="append" type="primary" @click="addTargetWallet">添加钱包</Button>
                    </Input>
                </div>
                <div class="wallet-box">
                    <div class="wallet-item" v-for="(item, key) in wallets" :key="key">
                        <div class="wallet" @click="walletOpt(item)">
                            <Button size="large" type="text">钱包地址: {{ item }}</Button>
                        </div>
                        <div class="time">
                            点击钱包地址进行操作
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <wallet-alert ref="wallet-alert"></wallet-alert>
    </div>
</template>
<script>

// import TermCon from '@/components/TermCon.vue'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            wallets: [],
            currentWallet: ''
        }
    },
    components: {
        // TermCon
    },
    computed: {

    },
    mounted() {
    },
    methods: {
        addTargetWallet() {
            if (this.currentWallet) {
                this.axios({
                    method: 'post',
                    url: this.$API.banWallet,
                    data: {
                        ips: [this.currentWallet]
                    }
                }).then(res => {
                    if (res.status == 200) {
                        this.$Message.success('添加黑名单成功!')

                        this.getList()
                    } else {
                        this.$Message.success('添加黑名单失败！')
                    }
                })
            }
        },
        show() {
            this.visable = true

            this.getList();
        },
        hide() {
            this.visable = false
        },
        getList() {
            this.loading = true
            
            this.axios({
                method: 'get',
                url: this.$API.getBanWallet
            }).then(res => {
                if (res.status == 200) {
                    this.wallets = res.data.reverse()
                } else {
                    this.$Message.error('获取钱包列表失败.')
                }

                this.loading = false
            })
        },
        walletOpt(wallet) {
            let getList = this.getList;

            this.$refs['wallet-alert'].show(wallet, getList)
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth){
    .wallet-ban-model{
        .md-w{
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth){
    .wallet-ban-model{
        width: 100%!important;
        max-width: 100%!important;
    }
}

.wallet-ban-model{
    margin: 0 auto;
    width: 50%;
    max-width: 1400px;
    overflow: hidden!important;
    overflow-y: auto!important;

    .wallet-box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .wallet-item{
            margin-right: 10px;
            margin-bottom: 10px;

            .wallet{
                display: flex;
                justify-content: center;
                font-size: 14px;
            }

            .time{
                text-align: center;
            }
        }
    }
}
</style>
