var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Form',{ref:"validateRef",staticClass:"pump-config-model",attrs:{"model":_vm.ProxyData,"label-position":"top","rules":_vm.RuleValidate}},[_c('Row',{attrs:{"gutter":25,"type":"flex","justify":"start"}},[_c('Col',{attrs:{"xs":24,"md":8,"lg":8}},[_c('Button',{staticClass:"item-add-wallet",attrs:{"type":"primary","long":""},nativeOn:{"click":function($event){return _vm.addExtract.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('lang.proxy.new_wallet')))]),_c('div',{staticClass:"wallet-item-list"},_vm._l((_vm.ProxyData.Extract),function(item,index){return _c('div',{key:index,class:_vm.classObject(index),on:{"click":function($event){return _vm.selectItem(index)}}},[_c('div',{staticClass:"item-wallet-icon"},[_c('Icon',{attrs:{"type":"logo-bitcoin","size":18}})],1),_c('div',{staticClass:"item-wallet-right"},[_c('div',{staticClass:"item-wallet-right-title"},[_vm._v(" "+_vm._s(_vm.$t('lang.proxy.allot_wallet'))+"-"+_vm._s(index+1)+" ")]),_c('div',{staticClass:"item-wallet-right-address"},[_vm._v(" "+_vm._s(item.Wallet)+" ")])])])}),0)],1),_c('Col',{attrs:{"xs":24,"md":16,"lg":16}},[_c('Row',{attrs:{"gutter":25,"type":"flex","justify":"start"}},[_c('Col',{attrs:{"xs":24,"md":15,"lg":15}},[_c('FormItem',{staticClass:"input-btn-group",attrs:{"label":_vm.$t('lang.proxy.fee_addr'),"prop":'Extract.' + _vm.activeIndex + '.Pool',"rules":{
                            required: _vm.poolValidator, 
                            message: _vm.$t('lang.proxy.fee_addr_message'), 
                            trigger: 'blur'
                        }}},[_c('Input',{attrs:{"placeholder":_vm.$t('lang.proxy.fee_addr_des')},model:{value:(_vm.ProxyData.Extract[_vm.activeIndex].Pool),callback:function ($$v) {_vm.$set(_vm.ProxyData.Extract[_vm.activeIndex], "Pool", $$v)},expression:"ProxyData.Extract[activeIndex].Pool"}}),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.selectPreProxy}},[_c('i',{staticClass:"el-icon-place"}),_vm._v(" 快速选择 ")])],1)],1),_c('Col',{attrs:{"xs":24,"md":9,"lg":9}},[_c('FormItem',{attrs:{"label":_vm.$t('lang.proxy.fee_deal')}},[_c('el-radio',{attrs:{"disable":_vm.relievePool,"label":"TCP"},model:{value:(_vm.ProxyData.Extract[_vm.activeIndex].Deal),callback:function ($$v) {_vm.$set(_vm.ProxyData.Extract[_vm.activeIndex], "Deal", $$v)},expression:"ProxyData.Extract[activeIndex].Deal"}},[_vm._v("TCP")]),_c('el-radio',{attrs:{"disabled":_vm.relievePool,"label":"SSL"},model:{value:(_vm.ProxyData.Extract[_vm.activeIndex].Deal),callback:function ($$v) {_vm.$set(_vm.ProxyData.Extract[_vm.activeIndex], "Deal", $$v)},expression:"ProxyData.Extract[activeIndex].Deal"}},[_vm._v("SSL")])],1)],1),_c('Col',{attrs:{"xs":24,"md":15,"lg":15}},[_c('FormItem',{staticClass:"input-btn-group",attrs:{"label":_vm.$t('lang.proxy.wallet_addr'),"prop":'Extract.' + _vm.activeIndex + '.Wallet',"rules":{
                            required: _vm.walletValidator, 
                            message: _vm.$t('lang.proxy.wallet_perc'), 
                            trigger: 'blur'
                        }}},[_c('Input',{attrs:{"placeholder":_vm.$t('lang.proxy.wallet_desc')},model:{value:(_vm.ProxyData.Extract[_vm.activeIndex].Wallet),callback:function ($$v) {_vm.$set(_vm.ProxyData.Extract[_vm.activeIndex], "Wallet", $$v)},expression:"ProxyData.Extract[activeIndex].Wallet"}}),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.selectPreWallet}},[_c('i',{staticClass:"el-icon-place"}),_vm._v(" 快速选择 ")])],1)],1),_c('Col',{attrs:{"xs":24,"md":9,"lg":9}},[_c('FormItem',{attrs:{"label":_vm.$t('lang.proxy.fee_name'),"prop":'Extract.' + _vm.activeIndex + '.DeviceName',"rules":{
                            required: _vm.nameValidator, 
                            message: _vm.$t('lang.proxy.wallet_perc'), 
                            trigger: 'blur'
                        }}},[_c('Input',{attrs:{"placeholder":_vm.$t('lang.proxy.fee_name_desc')},model:{value:(_vm.ProxyData.Extract[_vm.activeIndex].DeviceName),callback:function ($$v) {_vm.$set(_vm.ProxyData.Extract[_vm.activeIndex], "DeviceName", $$v)},expression:"ProxyData.Extract[activeIndex].DeviceName"}})],1)],1),_c('Col',{attrs:{"xs":24,"md":24,"lg":24}},[_c('FormItem',{attrs:{"label":_vm.$t('lang.proxy.fee_percent')}},[_c('Slider',{attrs:{"step":0.01,"min":0,"max":100,"show-input":""},model:{value:(_vm.ProxyData.Extract[_vm.activeIndex].Percent),callback:function ($$v) {_vm.$set(_vm.ProxyData.Extract[_vm.activeIndex], "Percent", $$v)},expression:"ProxyData.Extract[activeIndex].Percent"}})],1)],1),_c('Col',{attrs:{"xs":24,"md":24,"lg":24}},[_c('FormItem',[_c('Button',{attrs:{"type":"error","long":""},on:{"click":function($event){return _vm.deleteActive(_vm.activeIndex)}}},[_vm._v(_vm._s(_vm.$t('lang.proxy.delete_wallet'))+"-"+_vm._s(_vm.activeIndex + 1))])],1)],1)],1)],1)],1),_c('select-preset-pool',{ref:"select-preset-pool"}),_c('select-preset-wallet',{ref:"select-preset-wallet"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }