<template>
    <div id="app">
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
	name: 'App',
	components: {
	},
methods: {
		...mapActions('meta', [
            'updateSystemInfo',
            'updateOriginConfig',
            'updateStartTime',
            'updateLocalVersion',
			// 'updateBaseInfo'
        ])
	},
	mounted() {
		// this.updateOriginConfig()

		// this.updateLocalVersion()

		// this.updateBaseInfo()
	}
}
</script>

<style lang="scss">
</style>
