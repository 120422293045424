<template>
    <Form class="deal-config-form" :model="ProxyData" label-position="top">
        <Row :gutter="25" type="flex" justify="start">
            <Col style="margin-bottom: 10px;" :xs="24" :md="24" :lg="24">
                <p v-show="ProxyData.ProxyDeal === 'TCP'">
                    <span class="deal-intro">[TCP]</span>
                    {{ $t('lang.proxy.tcp_intro') }}
                </p>
                <p v-show="ProxyData.ProxyDeal === 'SSL'">
                    <span class="deal-intro">[SSL]</span>
                    {{ $t('lang.proxy.ssl_intro') }}
                </p>
                <p v-show="ProxyData.ProxyDeal === 'RMS'">
                    <span class="deal-intro">[RMS]</span>
                    {{ $t('lang.proxy.rms_intro') }}
                </p>
                <p v-show="ProxyData.ProxyDeal === 'KENC'">
                    <span class="deal-intro">[KENC]</span>
                    兼容老KT的本地客户端协议, KENC客户端可直接接入这里。
                </p>
                <p v-show="ProxyData.ProxyDeal === 'SOCKS5'">
                    <span class="deal-intro">[SOCKS5]</span>
                </p>
                <p v-show="ProxyData.ProxyDeal === 'TTS'">
                    <span class="deal-intro">[TCP/TLS]</span>
                    端口允许混合协议进入, 设备可通过TCP或SSL方式都可进入此端口。
                </p>
            </Col>
        </Row>
        <Row :gutter="25" type="flex" justify="start">
            <Col style="margin-bottom: 10px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('TCP')" @click.native="selectActive('TCP')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item deal-radio" v-model="ProxyData.ProxyDeal" label="TCP">TCP</el-radio>
                            <img src="@/assets/tcp.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 10px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('SSL')" @click.native="selectActive('SSL')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" v-model="ProxyData.ProxyDeal" label="SSL">SSL/TLS</el-radio>
                            <img src="@/assets/ssl.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 10px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('TTS')" @click.native="selectActive('TTS')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" v-model="ProxyData.ProxyDeal" label="TTS">TCP/TLS双协议</el-radio>
                            <img src="@/assets/mixin.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 10px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('RMS')" @click.native="selectActive('RMS')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" v-model="ProxyData.ProxyDeal" label="RMS">RMS</el-radio>
                            <img src="@/assets/rust.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 10px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <Card style="height:100%;" v-bind:class="classObject('KENC')" @click.native="selectActive('KENC')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" v-model="ProxyData.ProxyDeal" label="KENC">KENC</el-radio>
                            <img src="@/assets/ktlogo.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
            <Col style="margin-bottom: 10px;" :xs="24" :md="6" :lg="6">
                <FormItem class="form-item-o">
                    <!-- <Card style="height:100%;" v-bind:class="classObject('SOCKS5')" @click.native="selectActive('SOCKS5')"> -->
                        <Card style="height:100%;" v-bind:class="classObject('SOCKS5')">
                        <div class="item-card">
                            <el-radio class="deal-radio-item" disabled v-model="ProxyData.ProxyDeal" label="SOCKS5">SOCKS5</el-radio>
                            <img src="@/assets/socket.png">
                        </div>
                    </Card>
                </FormItem>
            </Col>
        </Row>
    </Form>
</template>
<script>
import ProxyData from '@/components/Proxy/data.js'

export default {
    data() {
        return {
            ProxyData: ProxyData.data
        }
    },
    computed: {
        classObject() {
            return (deal) => {
                let result = {
                    active: false
                }

                if (this.ProxyData.ProxyDeal == deal) {
                    result.active = true
                }
                
                return result
            }
        }
    },
    mounted() {
    },
    methods: {
        selectActive(deal) {
            this.ProxyData.ProxyDeal = deal
        }
    }
}
</script>

<style lang="scss">
    .deal-config-form{
        margin-bottom: 20px;

        .ivu-form-item-content{
            height: 100%;
        }

        .form-item-o{
            height: 100%;

            .ivu-card-body{
                height: 100%;
                padding: 0 16px;

                .item-card{
                    height: 100%;
                }
            }
        }

        .deal-intro{
            color: var(--font-color-1);
            font-weight: bold;
        }

        .deal-radio-item{
            margin: 0;
        }
    }
</style>
<style lang="scss" scoped>

</style>
