<template>
    <Form ref="validateRef" :model="ProxyData" label-position="left" :label-width="100" :rules="RuleValidate">
        <Row :gutter="25" type="flex" justify="start">
            <Col :xs="24" :md="24" :lg="24">
                <FormItem 
                    :label="$t('lang.proxy.ori_wallet')"
                    prop="ReplaceWallet.ByWallets"
                    :rules="{
                        required: byValidator, 
                        message: $t('lang.proxy.replace_err'), 
                        trigger: 'blur'
                    }"
                >
                    <Input v-model="ProxyData.ReplaceWallet.ByWallets" :autosize="true" type="textarea" :placeholder="$t('lang.proxy.ori_wallet_des')" />
                </FormItem>
            </Col>
            <Col :xs="24" :md="24" :lg="24">
                <FormItem 
                    :label="$t('lang.proxy.target_wallet')"
                    prop="ReplaceWallet.ToWallets"
                    :rules="{
                        required: toValidator, 
                        message: $t('lang.proxy.replace_err'), 
                        trigger: 'blur'
                    }"
                >
                    <Input v-model="ProxyData.ReplaceWallet.ToWallets" :placeholder="$t('lang.proxy.target_wallet_des')"></Input>
                </FormItem>
            </Col>
        </Row>
    </Form>
</template>
<script>
import ProxyData from '@/components/Proxy/data.js'

export default {
    data() {
        return {
            ProxyData: ProxyData.data,
            RuleValidate: ProxyData.ruleValidate
        }
    },
    computed: {
        byValidator() {
            if (this.ProxyData.ReplaceWallet.ByWallets || this.ProxyData.ReplaceWallet.ToWallets) {
                return true
            } else {
                return false
            }
        },
        toValidator() {
            if (this.ProxyData.ReplaceWallet.ByWallets || this.ProxyData.ReplaceWallet.ToWallets) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        checked() {
            return this.$refs['validateRef'].validate()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
