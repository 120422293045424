import axios from 'axios'
import CONFIG from '@/script/config.js'
import iView from 'iview';

const $CONFIG = CONFIG.CONFIG

const state = () => ({
    ports: []
})

const getters = {
    getPorts: (state, getters, rootState) => {
        return state.ports
    },
    getLocalPortById: (state, getters) => {
        return id => {
            for (let i = 0; i < state.ports.length; i++) {
                if (state.ports[i].id == id) {
                    return state.ports[i]
                }
            }
        }
    }
}

const sortBy = (attr, rev) => {
    if (rev == undefined) {
        rev = 1;
    } else {
        rev = (rev) ? 1 : -1;
    }

    return function (a, b) {
        a = Number(a.port);
        b = Number(b.port);
        if (a < b) {
            return rev * -1;
        }
        if (a > b) {
            return rev * 1;
        }
        return 0;
    }
}

const actions = {
    // 获取并更新全部服务数据
    updatePorts ({ state, commit }, callback) {
        return new Promise((resolve, reject) => {
            iView.LoadingBar.start();

            axios({
                method: 'get',
                url: $CONFIG.API.getPorts
            }).then(res => {
                iView.LoadingBar.finish();
                
                // 将新的数据结构转换成老的, 省的改组件了
                let tidyData = [];
                res.data.forEach((item, index) => {
                    tidyData.push(item.server)
                    tidyData[index].wallets = item.wallets
                    tidyData[index].stat = item.stat
                    tidyData[index].setting = item.setting
                    tidyData[index].__online = item.stat.online
                    tidyData[index].__delay = item.stat.delay
                })

                res.data = tidyData

                if (res.data.length) {
                    let sortPorts = res.data.sort(sortBy('port'));

                    commit('setTotalPort', tidyData)
                }

                resolve(res);

                callback && callback()
            })
        })
    }
}

const mutations = {
    setTotalPort (state, data) {
        state.ports = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}