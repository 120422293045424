<template>
    <div class="loader-box">
        <div class="loader"></div>
    </div>
</template>
<script>

export default {
    data() {
        return {
        }
    },
    computed:{
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
.loader-box{
    min-height: 34px;
    display: flex;
    align-items: center;
}

.loader{
      display: block;
      position: relative;
      height: 12px;
      width: 100%;
      border: 1px solid var(--shadow-color-1);
      border-radius: 10px;
      overflow: hidden;
    }
    .loader::after {
      content: '';
      width: 40%;
      height: 100%;
      background: var(--color-3);
      position: absolute;
      border-radius: 5px;
      top: 0;
      left: 0;
      box-sizing: border-box;
      animation: animloader .8s linear infinite;
    }
    
    @keyframes animloader {
      0% {
        left: 0;
        transform: translateX(-100%);
      }
      100% {
        left: 100%;
        transform: translateX(0%);
      }
    }
    
</style>
