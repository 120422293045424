import Vue from 'vue'
import VueRouter from 'vue-router'
import iView from 'iview';
import store from './../store'
// import Swal from 'sweetalert2'
// import i18n from '@/locales/i18n'

Vue.use(iView);

Vue.use(VueRouter)

const System = () => import('../views/SystemView.vue')
const Home = () => import('../views/HomeView.vue')
// const Proxy = () => import('../views/ProxyView.vue')
// const CoinList = () => import('../views/CoinList.vue')
// const PortList = () => import('../views/PortList.vue')
const PortInside = () => import('../views/PortInside.vue')
const LoginView = () => import('../views/LoginView.vue')
const ChartCardView = () => import('../views/HomeChartCard.vue')
const ControlView = () => import('../views/ClientControl.vue')

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/system/index/chartcard'
    },
    {
        path: '/login',
        name: '登陆',
        component: LoginView
    },
    {
        path: '/system',
		name: '仪表盘',
		component: System,
        redirect: '/system/index/chartcard',
		children: [
            {
                path: 'index',
                name: '综合统计',
                component: Home,
                redirect: '/system/index/chartcard',
                meta: {
                    number: '1'
                },
                children: [
                    {
                        path: 'chartcard',
                        name: '预览',
                        component: ChartCardView,
                        meta: {
                            number: '1',
                            marked: 'chartcard'
                        }
                    },
                    {
                        path: 'portinside',
                        name: '设备列表',
                        component: PortInside,
                        meta: {
                            number: '2',
                            marked: 'portInside'
                        }
                    }
                ]
            },
            {
                path: 'control',
                name: '群控',
                component: ControlView,
                meta: {
                    number: '7'
                }
            }
        ]
    }
]

const router = new VueRouter({
  routes
})

// 屏蔽重复点击路由时的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    iView.LoadingBar.start();

    if (store.getters["meta/getCurrency"].BTC || to.path == '/login' || to.name == '观察者') {
        store.dispatch("meta/updateStartTime")
		next();
	} else {
		store.dispatch("meta/updateCurrency").then(res => {
            next();
            store.dispatch("meta/updateStartTime")
		}).catch(err => {
            iView.LoadingBar.finish();
        });
	}
})

router.afterEach((to, from) => {
	iView.LoadingBar.finish();
})

export default router
