import i18n from '@/locales/i18n'

const data = {
	ProxyDeal: 'TCP',	// 端口协议
	ProxyCoin: '',	// 代理币种
	Port: null,			// 端口号
	MaxConnect: 65535,		// 最大连接数, 0为无上限
	Info: '',				// 端口备注
	e9: true,
	// 目标矿池配置
	ProxyPool: {
		Pool: '',			// 地址
		Deal: 'TCP'			// 协议
	},
	// 备用矿池配置
	SparePool: {
		Pool: '',			// 地址
		Deal: 'TCP'			// 协议
	},
	// 抽水配置
	Extract: [
		{
			sId: '',		// 服务器分配的钱包id, 只有创建过之后才有
			Wallet: '',		// 钱包地址
			Pool: '',		// 矿池地址
			Deal: 'TCP',	// 矿池协议
			Percent: 0,		// 抽水比例, 百分比, 精确到千分位
			DeviceName: ''	// 名称
		}
	],
	// 连接模式
	ConnectMode: {
		Mode: 'Efficient',			// 高效 Efficient, 兼容 Compatible, Pool 矿池模式
		Efficient:{					// 高效模式附加参数
			AutoCompatible: true		// 高无效设备自动转兼容模式
		},
		Compatible: {},				// 兼容模式附加参数
		Pool: {						// 矿池模式附加参数
			UnifyWallet: '',			// 统一钱包地址
			UnifyDeviceName: ''			// 统一名称
		}
	},
	// 替换指定钱包
	ReplaceWallet: {
		ByWallets: '',		// 遇到这些钱包需要替换, 格式一行一个钱包 (替换时需要忽略掉我们的内抽钱包, 避免被拦截抽水)
		ToWallets: ''		// 替换成这个钱包
	},
	// 拓展配置
	setting: {
		cp_name: "",
		cp_mode: 1,
		pu_mode: 0,
		et_mode: 0,
		pth: 0,	// 算力保护, 为了兼容老版本, 0和null为开启, 1为关闭
		cut: 0,	// 并发优化, 0和null为开, 1为关闭
		fr: 0,	// 强制下发成功份额
		op: 0	// 是否开启okminer优化
	}
}

const reset = () => {
	data.Extract = [createExtract()]
}

const ruleValidate = {
	'Port': {
		required: true,
		type: 'number',
		// message: '请输入要监听的端口, 范围1-65534',
		trigger: 'blur',
		validator: (rule, value, callback) => {
			if (value > 0 && value < 65535) {
				callback()
			} else {
				callback(new Error(i18n.t('lang.proxy.err_port')));
			}
		}
	},
	// 'setting.cp_name': {
	// 	required: true,
	// 	// message: '如果选择此模式, 此处必填项',
	// 	trigger: 'blur',
	// 	validator: (rule, value, callback) => {
	// 		if (data.setting.cp_mode == 1 && !data.setting.cp_name) {
	// 			callback(new Error('如果选择此模式, 此处必填项'))
	// 		} else {
	// 			callback()
	// 		}
	// 	}
	// },
	'ProxyCoin': {
		required: true,
		message: i18n.t('lang.proxy.err_proxy_coin'),
		trigger: 'blur'
	},
	'ProxyPool.Pool': {
		required: true,
		message: i18n.t('lang.proxy.err_pool_addr'),
		trigger: 'blur'
	},
	'ConnectMode.Pool.UnifyWallet': {
		required: true,
		// message: '矿池模式下此项必填',
		trigger: 'blur',
		validator: (rule, value, callback) => {
			if (
				(data.ConnectMode.Mode != 'Pool') ||
				(data.ConnectMode.Mode == 'Pool' && data.ConnectMode.Pool.UnifyWallet)) {
				callback()
			} else {
				callback(new Error(i18n.t('lang.proxy.err_pool_mode')))
			}
		}
	},
	'ConnectMode.Pool.UnifyDeviceName': {
		required: true,
		trigger: 'blur',
		validator: (rule, value, callback) => {
			if (
				(data.ConnectMode.Mode != 'Pool') ||
				(data.ConnectMode.Mode == 'Pool' && data.ConnectMode.Pool.UnifyDeviceName)) {
				callback()
			} else {
				callback(new Error(i18n.t('lang.proxy.err_pool_mode')))
			}
		}
	}
}

const createExtract = (Pool, Deal) => {
	return {
		sId: '',
		Wallet: '',
		Pool: Pool ? Pool : '',
		Deal: Deal ? Deal : 'TCP',
		Percent: 0,
		DeviceName: ''
	}
}

// 转为服务器数据
const transDataToServe = (data, isEdit) => {
	let res = {}

	res.port = data.Port; 	// 端口号
	res.name = data.Info;	// 备注
	res.currency = data.ProxyCoin;

	if (data.e9) {
		res.nc = 1
	} else {
		res.nc = 0
	}

    res.limit_connections = data.MaxConnect;
	res.pool_address = data.ProxyPool.Pool;
	res.connect_mode = (data.ProxyPool.Deal == 'TCP') ? 0 : 1;
	res.pool_address2 = data.SparePool.Pool;
	res.connect_mode2 = (data.SparePool.Deal == 'TCP') ? 0 : 1;

	if (data.ConnectMode.Mode == 'Efficient') {
		res.mode = 0
	} else if (data.ConnectMode.Mode == 'Compatible') {
		res.mode = 1
	} else {
		res.mode = 2;
		res.proxy_addr = data.ConnectMode.Pool.UnifyWallet
		res.proxy_device = data.ConnectMode.Pool.UnifyDeviceName
	}

	// 不要这个条件判断, 没有设置替换钱包就传空字符串过去, 否则已经配置的钱包替换没办法清掉
	// if (data.ReplaceWallet.ByWallets && data.ReplaceWallet.ToWallets) {
		res.pattern_addr = data.ReplaceWallet.ByWallets || ''
		res.replace_addr = data.ReplaceWallet.ToWallets || ''
	// }

	res.wallets = [];

	for (let i = 0; i < data.Extract.length; i++) {
		let wallet = data.Extract[i];
		if (wallet.Percent || isEdit) {
			let insert = {}
			insert.addr = wallet.Wallet
			insert.device = wallet.DeviceName
			insert.pool_address = wallet.Pool

			if (wallet.Deal == 'TCP') {
				insert.pool_protocol = 0
			} else if (wallet.Deal == 'SSL') {
				insert.pool_protocol = 1
			}

			insert.ratio = wallet.Percent / 100;

			if (wallet.sId) {
				insert.id = wallet.sId
			}

			res.wallets.push(insert)
		}
	}

	res.setting = data.setting;

	// 补丁
	res.setting.port = data.Port;
	res.setting.cp_name = ""
	// res.setting.pu_mode = 0

	if (data.ProxyDeal === 'TCP') {
		res.protocol = 0
		res.setting.et_mode = 0
	} else if (data.ProxyDeal === 'SSL') {
		res.protocol = 1
		res.setting.et_mode = 0
	} else if (data.ProxyDeal === 'RMS') {
		res.protocol = 2
		res.setting.et_mode = 0
	} else if (data.ProxyDeal === 'TTS') {
		res.protocol = 3
		res.setting.et_mode = 0
	} else if (data.ProxyDeal === 'KENC') {
		res.protocol = 1
		res.setting.et_mode = 1
	} else if (data.ProxyDeal === 'SOCKS5') {
		// todo
		res.protocol = 0
		res.setting.et_mode = 2
	}

	return res;
}

// 服务器数据转本地
const transServeToData = (serveData) => {
	let serve = serveData.server;
	let wallet = serveData.wallets;
	let setting = serveData.setting;

	data.Port = serve.port;
	data.Info = serve.name;
	data.ProxyCoin = serve.currency;

	if (serve.nc) {
		data.e9 = true
	} else {
		data.e9 = false
	}

	if (setting) {
		if (setting.et_mode) {
			if (setting.et_mode === 1) {
				data.ProxyDeal = 'KENC'
			} else if (setting.et_mode === 2) {
				data.ProxyDeal = 'SOCKS5'
			}
		} else {
			if (serve.protocol === 0) {
				data.ProxyDeal = 'TCP'
			} else if (serve.protocol === 1) {
				data.ProxyDeal = 'SSL'
			} else if (serve.protocol === 2) {
				data.ProxyDeal = 'RMS'
			} else if (serve.protocol === 3) {
				data.ProxyDeal = 'TTS'
			}
		}
	} else {
		if (serve.protocol === 0) {
			data.ProxyDeal = 'TCP'
		} else if (serve.protocol === 1) {
			data.ProxyDeal = 'SSL'
		} else if (serve.protocol === 2) {
			data.ProxyDeal = 'RMS'
		} else if (serve.protocol === 3) {
			data.ProxyDeal = 'TTS'
		}
	}

	data.MaxConnect = serve.limit_connections;
	data.ProxyPool.Pool = serve.pool_address
	if (serve.connect_mode === 0) {
		data.ProxyPool.Deal = 'TCP'
	} else {
		data.ProxyPool.Deal = 'SSL'
	}
	data.SparePool.Pool = serve.pool_address2
	if (serve.connect_mode2 === 0) {
		data.SparePool.Deal = 'TCP'
	} else {
		data.SparePool.Deal = 'SSL'
	}

	if (serve.mode === 0) {
		data.ConnectMode.Mode = 'Efficient'
	} else if (serve.mode === 1) {
		data.ConnectMode.Mode = 'Compatible'
	} else {
		data.ConnectMode.Mode = 'Pool'
		data.ConnectMode.Pool.UnifyWallet = serve.proxy_addr
		data.ConnectMode.Pool.UnifyDeviceName = serve.proxy_device
	}

	data.ReplaceWallet.ByWallets = serve.pattern_addr
	data.ReplaceWallet.ToWallets = serve.replace_addr


	let serveWallets = serve.wallets;

	data.Extract = [createExtract()]

	for (let i = 0; i < serveWallets.length; i++) {
		let item = serveWallets[i];
		let deal = 'TCP';

		if (item.pool_protocol === 0) {
			deal = 'TCP'
		} else {
			deal = 'SSL'
		}

		if (i == 0) {
			data.Extract[i].Wallet = item.addr
			data.Extract[i].Pool = item.pool_address
			data.Extract[i].Deal = deal
			data.Extract[i].Percent = item.ratio * 100
			data.Extract[i].DeviceName = item.device
			data.Extract[i].sId = item.id
		} else {
			data.Extract[i] = {
				Wallet: item.addr,
				Pool: item.pool_address,		// 矿池地址
				Deal: deal,	// 矿池协议
				Percent: item.ratio * 100,		// 抽水比例, 百分比, 精确到千分位
				DeviceName: item.device,	// 名称
				sId: item.id
			}
		}
	}

	if (setting) {
		data.setting = setting;
		data.setting.cp_name = ""

		if (setting.pu_mode == null) {
			data.setting.pu_mode = 0
		}

		if (setting.pth == null) {
			data.setting.pth = 0
		}

		if (setting.cut == null) {
			data.setting.cut = 0;
		}

		if (setting.fr == null) {
			data.setting.fr = 0
		}

		if (setting.op == null) {
			data.setting.op = 0
		}
	}
}

export default {
	data,
	createExtract,
	ruleValidate,
	transDataToServe,
	transServeToData,
	reset
}
